// src/components/TermsAndConditions.js
import React, { useEffect, useState } from "react";
import { Container, Typography, Paper, Box } from "@mui/material";
import ReactMarkdown from "react-markdown";

const TermsAndConditions = () => {
  const [markdownContent, setMarkdownContent] = useState("");

  useEffect(() => {
    // Citește fișierul markdown din public
    fetch("/termeni-si-conditii.md")
      .then((response) => response.text())
      .then((data) => setMarkdownContent(data));
  }, []);

  return (
    <Container maxWidth="lg" sx={{ marginTop: 8 }}>
      <Paper elevation={0} sx={{ padding: 3 }}>
        <ReactMarkdown>{markdownContent}</ReactMarkdown>
      </Paper>
    </Container>
  );
};

export default TermsAndConditions;
