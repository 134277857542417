import React from "react";
import { Typography } from "@mui/material";
import {
  StyledContainer,
  PageTitle,
  ContentSection,
  StyledList,
  InfoBox,
  textStyles,
} from "../../components/common/StyledComponents";

const PelerinajMoldovaBucovina = () => {
  return (
    <StyledContainer>
      <PageTitle>Pelerinaj în Moldova și Bucovina</PageTitle>

      <ContentSection title="Informații Principale">
        <InfoBox>
          <Typography sx={textStyles.regular}>
            <b>Perioada</b>: 14-16 Martie 2025
          </Typography>
          <Typography sx={textStyles.regular}>
            <b> Înscrieri și informații </b>: 0755 182 802
          </Typography>
          <Typography sx={textStyles.regular}>
            <b> Grupul va fi însoțit de părintele Valeriu Mircea Vana </b>
          </Typography>
          <Typography sx={textStyles.regular}>
            Agenția Pelerinul Creștin desfășoară un pelerinaj in 𝗠𝗼𝗹𝗱𝗼𝘃𝗮 ș𝗶
            𝗕𝘂𝗰𝗼𝘃𝗶𝗻𝗮 in perioada 𝟭𝟰-𝟭𝟲 𝗺𝗮𝗿𝘁𝗶𝗲 𝟮𝟬𝟮𝟱! Mănăstirile pline de har din
            aceste ținuturi își deschid porțile, iar sfinții ocrotitori își
            așteaptă pelerinii cu brațele nevăzute ale rugăciunii.
          </Typography>{" "}
          <Typography sx={textStyles.regular}>
            Vom merge împreună pe urmele sfinților, închinându-ne la S𝗳â𝗻𝘁𝗮
            𝗖𝘂𝘃𝗶𝗼𝗮𝘀ă 𝗣𝗮𝗿𝗮𝘀𝗰𝗵𝗲𝘃𝗮 𝗹𝗮 𝗜𝗮ș𝗶 ș𝗶 𝗹𝗮 𝗦𝗳â𝗻𝘁𝘂𝗹 𝗜𝗼𝗮𝗻 𝗰𝗲𝗹 𝗡𝗼𝘂 𝗱𝗲 𝗹𝗮
            𝗦𝘂𝗰𝗲𝗮𝘃𝗮, dar și la cele mai importante mănăstiri care au păstrat vie
            flacăra credinței de-a lungul veacurilor. Descoperă Sfințenia
            Moldovei și Bucovinei!{" "}
          </Typography>
        </InfoBox>
      </ContentSection>

      <ContentSection title="Obiective Turistice">
        <Typography sx={textStyles.regular}>
          Locuri de rugăciune și istorie vie:
        </Typography>
        <StyledList>
          <li>
            ⛪ 𝗠ă𝗻ă𝘀𝘁𝗶𝗿𝗲𝗮 𝗩𝗼𝗿𝗼𝗻𝗲ț – Ctitorită în 1488 de Ștefan cel Mare,
            această mănăstire este celebră în întreaga lume pentru "Albastrul de
            Voroneț", o nuanță misterioasă care a rezistat de secole. Aici se
            odihnește și marele sfânt pustnic Daniil Sihastrul, duhovnicul
            domnitorului.
          </li>
          <li>
            ⛪ 𝗠ă𝗻ă𝘀𝘁𝗶𝗿𝗲𝗮 𝗛𝘂𝗺𝗼𝗿 – Un alt monument al artei medievale
            moldovenești, Humorul impresionează prin frescele sale exterioare
            din secolul al XVI-lea, păstrate aproape intacte. Ctitorită de
            logofătul Toader Bubuiog cu binecuvântarea lui Petru Rareș,
            mănăstirea este un simbol al credinței și al artei bizantine din
            Moldova.
          </li>
          <li>
            ⛪ 𝗠ă𝗻ă𝘀𝘁𝗶𝗿𝗲𝗮 𝗡𝗲𝗮𝗺ț – Cea mai veche mănăstire din Moldova, cu
            începuturi în secolul al XIV-lea, este considerată „Ierusalimul
            Ortodoxiei Românești”. Aici a viețuit și a scris Sfântul Paisie
            Velicicovschi, unul dintre cei mai mari duhovnici ai ortodoxiei.
            Biblioteca mănăstirii, una dintre cele mai vechi din țară, păstrează
            manuscrise medievale de o valoare inestimabilă.
          </li>
          <li>
            ⛪𝗠ă𝗻ă𝘀𝘁𝗶𝗿𝗲𝗮 𝗣ă𝘁𝗿ă𝘂ț𝗶 – Ctitorie a lui Ștefan cel Mare, este cea mai
            veche biserică păstrată intactă dintre toate ctitoriile sale. Face
            parte din patrimoniul UNESCO și impresionează prin frescele sale
            unice, printre care se află și celebra scenă a Cavalcadei Sfinților
            Militari.
          </li>
          <li>
            ⛪ 𝗠ă𝗻ă𝘀𝘁𝗶𝗿𝗲𝗮 𝗣𝘂𝘁𝗻𝗮 – Supranumită de Mihai Eminescu „Ierusalimul
            Neamului Românesc”, mănăstirea este locul de odihnă al marelui
            domnitor Ștefan cel Mare, alături de familia sa. Un loc de profundă
            spiritualitate și un simbol național al identității noastre.
          </li>
          <li>
            ⛪ 𝗠ă𝗻ă𝘀𝘁𝗶𝗿𝗲𝗮 𝗩ă𝗿𝗮𝘁𝗲𝗰 – Un loc de liniște sufletească, unde se află
            mormântul Veronicăi Micle, marea iubire a lui Mihai Eminescu.
            Mănăstirea este un important centru monahal de maici, cu o tradiție
            de rugăciune neîntreruptă de peste 200 de ani.
          </li>
          <li>
            ⛪ 𝗖𝗮𝘁𝗲𝗱𝗿𝗮𝗹𝗮 𝗠𝗶𝘁𝗿𝗼𝗽𝗼𝗹𝗶𝘁𝗮𝗻ă 𝗱𝗶𝗻 𝗜𝗮ș𝗶 – Cel mai mare loc de pelerinaj
            din Moldova, unde se află moaștele Sfintei Cuvioase Parascheva,
            făcătoare de minuni și ocrotitoarea întregii țări.
          </li>
          <li>
            ⛪ 𝗖𝗮𝘁𝗲𝗱𝗿𝗮𝗹𝗮 𝗔𝗿𝗵𝗶𝗲𝗽𝗶𝘀𝗰𝗼𝗽𝗮𝗹ă 𝗱𝗶𝗻 𝗦𝘂𝗰𝗲𝗮𝘃𝗮 – Aici se află moaștele
            Sfântului Ioan cel Nou, ocrotitorul Bucovinei, un sfânt martir din
            secolul al XIV-lea, venerat pentru minunile săvârșite asupra celor
            care îi cer ajutorul.
          </li>
        </StyledList>
      </ContentSection>

      <ContentSection title="Servicii Incluse">
        <StyledList>
          <li>
            Transport cu autocarul, 2 nopți de cazare, 3 mese (o masă pe zi),
            ghid religios, preot însoțitor, alte bucurii duhovnicești
          </li>
        </StyledList>
      </ContentSection>

      <ContentSection title="Servicii Neincluse">
        <StyledList>
          <li>Intrările la mănăstiri și obiective turistice și culturale</li>
        </StyledList>
      </ContentSection>

      <ContentSection title="Tarif">
        <Typography sx={textStyles.regular}>1000 LEI</Typography>
      </ContentSection>

      <ContentSection title="Informații Înscriere">
        <Typography sx={textStyles.regular}>
          Informații și înscrieri la numărul de telefon: 0755 182 802 (Pr. dr.
          Valeriu-Mircea VANA)
        </Typography>
        <StyledList>
          <li>
            Confirmarea locului se face în 5 zile de la data înscrierii
            printr-un avans
          </li>
          <li>
            Locurile în autocar se distribuie în ordinea achitării avansului
          </li>
        </StyledList>
      </ContentSection>

      <ContentSection title="Note Importante">
        <StyledList>
          <li>
            Minorii trebuie sa calatoreasca cu cel putin un adult insotitor
          </li>
          <li>
            Programul actiunii poate fi modificat de catre insotitorul de grup,
            ca ordine de desfasurare sau se pot inlocui unele obiective sau
            hoteluri in functie de anumite situatii speciale
          </li>
          <li>
            Distributia camerelor la hotel se face de catre receptia acestuia;
            problemele legate de amplasarea sau aspectul camerei se rezolva de
            catre turist direct la receptie, asistat de insotitorul de grup
          </li>
          <li>
            Agentia nu se obliga sa gaseasca partaj persoanelor care calatoresc
            singure
          </li>
        </StyledList>
      </ContentSection>

      <ContentSection title="Pregătirea Duhovnicească pentru Pelerinaj">
        <Typography sx={{ ...textStyles.regular, mb: 2 }}>
          Vă sfătuim cu părintească grijă:
        </Typography>
        <StyledList>
          <li>
            să vă spovediţi şi să luaţi binecuvântarea duhovnicului dvs. pentru
            a face acest Pelerinaj pe urmele Sfinților
          </li>
          <li>să iertaţi pe cei cu care aţi avut conflicte şi supărări</li>
          <li>să citiţi în această perioadă din viețile Sfinților, acatiste</li>
          <li>
            Să pregătiţi de acasă pomelnicele cu numele celor apropiaţi –
            pomelnice pe care doriţi să le lăsaţi în bisericile importante din
            Grecia (să scrieţi întotdeauna numele de botez a celui pomenit - cu
            majuscule, fără diminutive)
          </li>
          <li>
            să pregătiţi batiste noi sau mici obiecte pe care le veţi atinge de
            Icoane făcătoare de minuni și de raclele cu Sfintele Moaște
          </li>
        </StyledList>
      </ContentSection>

      <Typography sx={{ ...textStyles.bold, mb: 2, textAlign: "center" }}>
        Vă așteptăm să fim împreună pelerini spre mântuire!
      </Typography>
    </StyledContainer>
  );
};

export default PelerinajMoldovaBucovina;
