import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Card,
  CardContent,
  CardMedia,
  Typography,
  Box,
  IconButton,
  Container,
  Grid,
  Tabs,
  Tab,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import { CustomContainedButton } from "./OutlinedButton";

export const PilgrimageCard = ({
  image,
  title,
  description,
  path,
  pret,
  data,
}) => {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate(path);
  };

  return (
    <Card
      sx={{
        height: "100%",
        display: "flex",
        flexDirection: "column",
        margin: "0 8px",
        borderRadius: "8px",
        boxShadow: "none",
        border: "1px solid lightgrey",
      }}
    >
      <CardMedia
        component="img"
        height="200"
        image={image}
        alt={title}
        sx={{ objectFit: "cover" }}
      />
      <CardContent
        sx={{
          p: 2,
          height: "100%",
          textAlign: "left",
          justifyContent: "space-between",
          justifyItems: "flex-start",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Box>
          <Typography
            variant="h6"
            gutterBottom
            component="h3"
            sx={{ fontSize: "1.1rem", fontWeight: "600" }}
          >
            {title}
          </Typography>
          <Typography
            variant="body2"
            color="text.secondary"
            sx={{ mb: 0.5, fontFamily: "Inter", fontWeight: 500 }}
          >
            Pret: {pret}
          </Typography>
          <Typography
            variant="body2"
            color="text.secondary"
            sx={{ mb: 2, fontFamily: "Inter", fontWeight: 500 }}
          >
            Data: {data}
          </Typography>
          <Typography
            variant="body2"
            color="text.secondary"
            sx={{ mb: 2, fontFamily: "Inter" }}
          >
            {description}
          </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "flex-end",
            justifyContent: "center",
            height: "fit-content",
          }}
        >
          <CustomContainedButton
            text="Citeste mai mult"
            onClick={handleClick}
          />
        </Box>
      </CardContent>
    </Card>
  );
};

const PilgrimageSlider = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [selectedCategory, setSelectedCategory] = useState("extern");
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const handleTabChange = (event, newValue) => {
    setSelectedCategory(newValue);
    setCurrentIndex(0);
  };

  const pageSize = isMobile ? 1 : 3;

  const pelerinajeExterne = [
    {
      titlu: "Pelerinaj în Sfântul Munte Athos",
      data: "25-30 Martie sau 15-20 Iunie 2025",
      pret: "600 Euro (Iunie: 650 Euro)",
      transport: "Autocar + Feribot",
      path: "/pelerinaje/athos",
      image: "/images/IMG_5707.JPG",
      description:
        "Transport autocar și vapor, 2 nopți la mănăstiri. Obiective religioase, icoane făcătoare de minuni.",
    },
    {
      titlu: "Pelerinaj în 𝗜𝗦𝗥𝗔𝗘𝗟",
      data: "𝟵 - 𝟭𝟯 𝗺𝗮𝗶 𝟮𝟬𝟮𝟱",
      pret: "850 Euro",
      transport: "Avion + Autocar",
      path: "/pelerinaje/israel",
      image: "/images/israel.jpeg",
      description:
        "Transport avion, 4 nopți de cazare (1 noapte în Nazaret/Tiberias, 3 în Bethleem) în regim demipensiune.",
    },
    {
      titlu: "Pelerinaj în Grecia",
      data: "21 - 28 Mai 2025",
      pret: "1100 Euro",
      transport: "Avion + Autocar",
      path: "/pelerinaje/grecia",
      image: "/images/IMG_5651.JPG",
      description:
        "Transport avion, cazare 7 nopți în regim demipensiune. Obiective: Atena, Meteora, Patras, Corfu, Evia. Sf. Patapie Catedrala Mitropolitană, locul martiriului Sfântul Apostol Andrei, încreștinătorul României",
    },
    {
      titlu: "Pelerinaj în Cappadocia și Asia Mică",
      data: "19-27 Februarie 2025",
      pret: "1100 EURO",
      transport: "Avion + Autocar",
      path: "/pelerinaje/cappadocia",
      image: "/images/IMG_5655.JPG",
      description:
        "Include transport cu avion și autocar, cazare 8 nopți la hoteluri de 4-5 stele, mese (mic dejun și cină), și ghid de turism religios. Vizite la locuri sacre precum Efes, Pamukkale, Cappadocia, Istanbul.",
    },
  ];

  const pelerinajeInterne = [
    {
      titlu: "Pelerinaj în Moldova și Bucovina",
      data: "14-16 martie 2025",
      pret: "1000 LEI",
      transport: "Autocar",
      path: "/pelerinaje/moldova",
      image: "/images/IMG_5648.JPG",
      description:
        "Vizite la Catedrala Sf. Sofia din Istanbul, Biserica Vlaherne, Mănăstirea Izvorul Tămăduirii, Biserica Românească Sf. Parascheva, Patriarhia Ecumenică...",
    },
    {
      titlu: "Pelerinaj în Banat și Cazanele Dunării",
      data: "𝟮𝟱-𝟮𝟳 𝗮𝗽𝗿𝗶𝗹𝗶𝗲 𝟮𝟬𝟮𝟱.",
      pret: "1000 lei",
      transport: "Autocar",
      path: "/pelerinaje/banat-cazane",
      image: "/images/IMG_5649.JPG",
      description:
        "Obiective religioase și culturale: Tismana, Cazanele Dunării, sculpturi Brâncuși.",
    },

    {
      titlu: "Pelerinaj Pe urmele Sfinților Brancoveni",
      data: "În curând",
      pret: "1000 lei",
      transport: "Autocar",
      path: "/pelerinaje/romania-grecia",
      image: "/images/IMG_5653.JPG",
      description:
        "Vizite la Sibiu, Târgoviște, Brașov, și diverse mănăstiri (Curtea de Argeș, Cotmeana, Sinaia).",
    },

    {
      titlu: "Pelerinaj în București și împrejurimi",
      data: "În curând",
      pret: "În curs de actualizare",
      transport: "Autocar",
      path: "/pelerinaje/bucuresti",
      image: "/images/IMG_5728.JPG",
      description:
        "Include Catedrala Patriarhală, Mănăstirea Snagov, Căldărușani, etc.",
    },
    {
      titlu: "Pelerinaj în Transilvania",
      data: "În curând",
      pret: "În curs de actualizare",
      transport: "Autocar",
      path: "/pelerinaje/transilvania",
      image: "/images/IMG_5772.JPG",
      description:
        "Vizite la Mănăstirea Sfanta Cruce - Oradea, Mănăstirea Izbuc, Mănăstirea Prislop, Pestera Sfantul Ioan, Mormantul pr. Arsenie Boca...",
    },
  ];

  const filteredPilgrimages =
    selectedCategory === "extern" ? pelerinajeExterne : pelerinajeInterne;
  const totalPages = Math.ceil(filteredPilgrimages.length / pageSize);

  return (
    <Container maxWidth="lg" sx={{ py: 4, width: "100%" }}>
      <Typography
        variant="h4"
        component="h1"
        gutterBottom
        sx={{
          pt: { xs: 3, md: 7 },
          fontFamily: "Libre Baskerville",
          fontWeight: 700,
          width: { xs: "100%", md: "600px" },
          margin: "0 auto",
          textAlign: "center",
        }}
      >
        Deschide porțile către o călătorie a sufletului
      </Typography>
      <Typography
        variant="body2"
        component="p"
        gutterBottom
        sx={{
          pb: { xs: 3, md: 5 },
          pt: 2,
          fontFamily: "Inter",
          width: { xs: "100%", md: "600px" },
          margin: "0 auto",
          textAlign: "center",
        }}
      >
        Pelerinajul religios este o căutare în lumea aceasta a ceea ce nu este
        din lumea aceasta „Împărăția lui Dumnezeu"
      </Typography>

      <Box sx={{ borderBottom: 0, borderColor: "divider", mb: 4 }}>
        <Tabs
          value={selectedCategory}
          onChange={handleTabChange}
          centered
          sx={{
            "& .MuiTab-root": {
              textTransform: "none",
              fontFamily: "Inter",
              fontSize: { xs: "16px", md: "16px" },
              fontWeight: 500,
              color: "text.primary",
              "&.Mui-selected": {
                color: "black",
              },
            },
            "& .MuiTabs-indicator": {
              backgroundColor: "black",
            },
          }}
        >
          <Tab
            label="Pelerinaje externe"
            value="extern"
            sx={{ px: { xs: 2, md: 4 } }}
          />
          <Tab
            label="Pelerinaje interne"
            value="intern"
            sx={{ px: { xs: 2, md: 4 } }}
          />
        </Tabs>
      </Box>

      <Box sx={{ position: "relative" }}>
        <IconButton
          onClick={() =>
            setCurrentIndex((prev) => (prev === 0 ? totalPages - 1 : prev - 1))
          }
          sx={{
            position: "absolute",
            left: { xs: -8, sm: -20 },
            top: "50%",
            transform: "translateY(-50%)",
            zIndex: 2,
            bgcolor: "black",
            boxShadow: 0,
            "&:hover": { bgcolor: "rgba(0,0,0,0.8)" },
            width: { xs: 32, sm: 40 },
            height: { xs: 32, sm: 40 },
          }}
        >
          <svg
            width="15"
            height="15"
            viewBox="0 0 15 15"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M6.85355 3.14645C7.04882 3.34171 7.04882 3.65829 6.85355 3.85355L3.70711 7H12.5C12.7761 7 13 7.22386 13 7.5C13 7.77614 12.7761 8 12.5 8H3.70711L6.85355 11.1464C7.04882 11.3417 7.04882 11.6583 6.85355 11.8536C6.65829 12.0488 6.34171 12.0488 6.14645 11.8536L2.14645 7.85355C1.95118 7.65829 1.95118 7.34171 2.14645 7.14645L6.14645 3.14645C6.34171 2.95118 6.65829 2.95118 6.85355 3.14645Z"
              fill="white"
              fillRule="evenodd"
              clipRule="evenodd"
            ></path>
          </svg>
        </IconButton>

        <Box
          sx={{
            overflow: "hidden",
            mx: { xs: 2, sm: 4 },
          }}
        >
          <Box
            sx={{
              display: "flex",
              transition: "transform 0.3s ease-in-out",
              transform: `translateX(-${currentIndex * 100}%)`,
              mb: 2,
            }}
          >
            {Array.from({ length: totalPages }).map((_, pageIndex) => (
              <Grid
                container
                spacing={2}
                key={pageIndex}
                sx={{
                  flexShrink: 0,
                  width: "100%",
                }}
              >
                {filteredPilgrimages
                  .slice(pageIndex * pageSize, (pageIndex + 1) * pageSize)
                  .map((pilgrimage, index) => (
                    <Grid
                      item
                      xs={12}
                      sm={pageSize === 1 ? 12 : 6}
                      md={pageSize === 3 ? 4 : 6}
                      key={index}
                    >
                      <PilgrimageCard
                        title={pilgrimage.titlu}
                        description={`${pilgrimage.description}`}
                        pret={pilgrimage.pret}
                        data={pilgrimage.data}
                        image={pilgrimage.image}
                        path={pilgrimage.path}
                      />
                    </Grid>
                  ))}
              </Grid>
            ))}
          </Box>
        </Box>

        <IconButton
          onClick={() =>
            setCurrentIndex((prev) => (prev === totalPages - 1 ? 0 : prev + 1))
          }
          sx={{
            position: "absolute",
            right: { xs: -8, sm: -20 },
            top: "50%",
            transform: "translateY(-50%)",
            zIndex: 2,
            bgcolor: "black",
            boxShadow: 0,
            "&:hover": { bgcolor: "rgba(0,0,0,0.8)" },
            width: { xs: 32, sm: 40 },
            height: { xs: 32, sm: 40 },
          }}
        >
          <svg
            width="15"
            height="15"
            viewBox="0 0 15 15"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M8.14645 3.14645C8.34171 2.95118 8.65829 2.95118 8.85355 3.14645L12.8536 7.14645C13.0488 7.34171 13.0488 7.65829 12.8536 7.85355L8.85355 11.8536C8.65829 12.0488 8.34171 12.0488 8.14645 11.8536C7.95118 11.6583 7.95118 11.3417 8.14645 11.1464L11.2929 8H2.5C2.22386 8 2 7.77614 2 7.5C2 7.22386 2.22386 7 2.5 7H11.2929L8.14645 3.85355C7.95118 3.65829 7.95118 3.34171 8.14645 3.14645Z"
              fill="white"
              fillRule="evenodd"
              clipRule="evenodd"
            ></path>
          </svg>
        </IconButton>
      </Box>

      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          gap: 1,
          mt: 2,
        }}
      >
        {Array.from({ length: totalPages }).map((_, index) => (
          <Box
            key={index}
            onClick={() => setCurrentIndex(index)}
            sx={{
              width: currentIndex === index ? 24 : 8,
              height: 8,
              borderRadius: 4,
              bgcolor: currentIndex === index ? "black" : "grey.300",
              transition: "all 0.3s ease-in-out",
              cursor: "pointer",
            }}
          />
        ))}
      </Box>
    </Container>
  );
};

export default PilgrimageSlider;
