import React from "react";
import {
  Container,
  Typography,
  Paper,
  styled,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import { PageTitle, SectionTitle } from "./common/StyledComponents";

const InfoText = styled(Typography)(({ theme }) => ({
  whiteSpace: "pre-line",
  marginTop: "20px",
  marginBottom: "30px",
  fontSize: "1rem",
  lineHeight: 1.7,
  fontFamily: "Inter",
  "& a": {
    color: theme.palette.primary.main,
    textDecoration: "none",
    "&:hover": {
      textDecoration: "underline",
    },
  },
}));

const InfoSection = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(3),
  marginBottom: theme.spacing(3),
  backgroundColor: "#fff",
}));

const UsefulInfo = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Container maxWidth="lg" sx={{ py: { xs: 5, md: 10 } }}>
      <PageTitle variant="h1">Informații utile</PageTitle>

      <InfoSection elevation={0}>
        <SectionTitle variant="h2">
          Plăți și rezervarea locului pentru pelerinaje
        </SectionTitle>
        <InfoText variant="body1">
          {`Informații utile despre efectuarea plăților și rezervarea locului pentru pelerinaje:
  
  Plata partiala sau integrala se poate face la 𝘀𝗲𝗱𝗶𝘂𝗹 𝗔𝗴𝗲𝗻𝘁𝗶𝗮 𝗣𝗲𝗹𝗲𝗿𝗶𝗻𝘂𝗹 𝗖𝗿𝗲𝘀𝘁𝗶𝗻 sau, dupa o rezervare anterioara, prin ordin de plata la orice sediu al Bancii Transilvania sau prin 𝘁𝗿𝗮𝗻𝘀𝗳𝗲𝗿 𝗯𝗮𝗻𝗰𝗮𝗿, fapt ce constitue consimtamantul si acordul deplin al  PELERINULUI asupra tuturor clauzelor contractuale, precum si asupra programului detaliat al pelerinajului.
  
  Pelerinii care doresc sa achite in lei pelerinajele cu tarife in euro sau dolari, pot face plata in urmatoarea modalitate :
  Se achita contravaloarea sumei in euro sau dolari la cursul BNR din ziua platii + 2%,comision de risc valutar.
  
  Banca Transilvania – Sucursala Baia Mare
  
  Cont lei: RO91BTRLRONCRT0475085201
  
  Cont euro: RO41BTRLEURCRT0475085201
  
  𝗟𝗮 𝘀𝗽𝗲𝗰𝗶𝗳𝗶𝗰𝗮ț𝗶𝗮 𝗽𝗹𝗮𝘁𝗶𝗶 𝘀𝗲 𝘃𝗮 𝘁𝗿𝗲𝗰𝗲 :
  
  - pelerinajul si data acestuia;
  
  - numărul de persoane participante si numele;
  
  - localitatea de domiciliu.
  
  Dupa efectuarea platii, 𝘀𝗲 𝘁𝗿𝗶𝗺𝗶𝘁𝗲 𝗽𝗲 𝗪𝗵𝗮𝘁𝘀𝗮𝗽𝗽 𝗹𝗮 𝗻𝘂𝗺ă𝗿𝘂𝗹 𝗱𝗲 𝘁𝗲𝗹𝗲𝗳𝗼𝗻 𝟬𝟳𝟱𝟱𝟭𝟴𝟮𝟴𝟬𝟮 sau pe adresa de e-mail pelerin.maramures@yahoo.com, copia documentului de plata prin banca.`}
        </InfoText>

        <SectionTitle variant="h2">Condiții de retragere</SectionTitle>
        <InfoText variant="body1">
          {`❗𝗖𝗼𝗻𝗱𝗶𝘁𝗶𝗶 𝗱𝗲 𝗿𝗲𝘁𝗿𝗮𝗴𝗲𝗿𝗲 𝗽𝗲𝗻𝘁𝗿𝘂 𝗽𝗹𝗮𝘁𝗮 𝗮𝘃𝗮𝗻𝘀𝘂𝗹𝘂𝗶:
  
  - avansul nu se restituie
  
  Conditii de retragere pentru plata integrala a pelerinajului:
  
  - 50% din preţul pachetului de servicii, dacă renunţarea se face cu mai mult de 20 zile calendaristice înainte de data plecării;
  
  -100 % din preţul pachetului de servicii, dacă renunţarea se face într-un interval mai mic de 10 zile calendaristice înainte de plecare sau prin neprezentarea la program.`}
        </InfoText>

        <SectionTitle variant="h2">
          Rezervarea locului pentru pelerinaj
        </SectionTitle>
        <InfoText variant="body1">
          {`
  𝗥𝗲𝘇𝗲𝗿𝘃𝗮𝗿𝗲𝗮 𝗹𝗼𝗰𝘂𝗹𝘂𝗶 𝗽𝗲𝗻𝘁𝗿𝘂 𝗽𝗲𝗹𝗲𝗿𝗶𝗻𝗮𝗷:
  
  Rezervarea locului pentru pelerinaj se poate 𝗳𝗮𝗰𝗲 𝘁𝗲𝗹𝗲𝗳𝗼𝗻𝗶𝗰: 𝟬𝟳𝟱𝟱𝟭𝟴𝟮𝟴𝟬𝟮 sau la 𝘀𝗲𝗱𝗶𝘂𝗹 𝗔𝗴𝗲𝗻𝘁𝗶𝗲𝗶  Pelerinul Creștin, bulevardul Traian 1B, Baia Mare, program 𝗟𝗨𝗡𝗜 - 𝗩𝗜𝗡𝗘𝗥𝗜: 𝗼𝗿𝗲𝗹𝗲 𝟵-𝟭𝟱.
  
  𝗔𝘃𝗮𝗻𝘀𝘂𝗹 𝘀𝗲 𝗮𝗰𝗵𝗶𝘁𝗮 𝗶𝗻 𝗺𝗮𝘅𝗶𝗺 𝟱 𝘇𝗶𝗹𝗲 𝗱𝗲 𝗹𝗮 𝗶𝗻𝘀𝗰𝗿𝗶𝗲𝗿𝗲, 𝗶𝗻 𝗰𝗮𝘇 𝗰𝗼𝗻𝘁𝗿𝗮𝗿 𝗿𝗲𝘇𝗲𝗿𝘃𝗮𝗿𝗲𝗮 𝘀𝗲 𝗮𝗻𝘂𝗹𝗲𝗮𝘇𝗮.
  
  𝗦𝘁𝗮𝗯𝗶𝗹𝗶𝗿𝗲𝗮 𝗹𝗼𝗰𝘂𝗿𝗶𝗹𝗼𝗿 𝗶𝗻 𝗮𝘂𝘁𝗼𝗰𝗮𝗿 𝘀𝗮𝘂 𝗺𝗶𝗰𝗿𝗼𝗯𝘂𝘇 𝘀𝗲 𝗳𝗮𝗰𝗲 𝗶𝗻 𝗳𝘂𝗻𝗰𝘁𝗶𝗲 𝗱𝗲 𝗱𝗮𝘁𝗮 𝗰𝗼𝗻𝗳𝗶𝗿𝗺𝗮𝗿𝗶𝗶 𝗹𝗼𝗰𝘂𝗹𝘂𝗶 𝗽𝗿𝗶𝗻 𝗮𝗰𝗵𝗶𝘁𝗮𝗿𝗲𝗮 𝗮𝘃𝗮𝗻𝘀𝘂𝗹𝘂𝗶 𝘀𝗮𝘂 𝗽𝗹𝗮𝘁𝗮 𝗶𝗻𝘁𝗲𝗴𝗿𝗮𝗹𝗮!
  
  Plata integrala se face cu 30 zile inainte de călătorie la pelerinajele externe și 15 zile la pelerinajele interne.       
  
  Efectuarea parțială sau integrală a oricărei plăți constituie consimțământul si acordul deplin al PELERINULUI asupra tuturor clauzelor contractuale, precum și asupra programului detaliat al pelerinajului.`}
        </InfoText>

        <SectionTitle variant="h2">
          Informații legale și condiții de călătorie
        </SectionTitle>
        <InfoText variant="body1">
          {`
  * CONDIȚII DE IEȘIRE DIN ȚARĂ PENTRU CETĂȚENII ROMÂNI MINORI:
  Condiții de ieșire din țară pentru cetățenii români minori - Politia de Frontiera
  
  * CONDIȚII DE CĂLĂTORIE IN GRECIA :
  Condiţii de călătorie | Ministry of Foreign Affairs (mae.ro)
  
  * CONDIȚII DE CĂLĂTORIE IN TURCIA:
  Condiţii de călătorie | Ministry of Foreign Affairs (mae.ro)
  
  * CONDIȚII DE CĂLĂTORIE IN EGIPT:
  Condiţii de călătorie | Ministry of Foreign Affairs (mae.ro)
  
  * HOTARARI ALE SFANTULUI SINOD AL B.O.R. CU PRIVIRE LA ORGANIZAREA PELERINAJ
  Hotărârea nr. 9.701 din 29 octombrie 2013 privind îndatoririle responsabililor de la locurile de pelerinaj din Biserica Ortodoxă Română (mănăstiri, parohii, schituri), pentru descurajarea pelerinajelor neautorizate.
   
  Hotărârea nr. 1837 din 28.02.2013 a Sfântului Sinod al Bisericii Ortodoxe Române privind modul de organizare şi funcţionare a agenţiilor de pelerinaj din Patriarhia Română.
   http://patriarhia.ro/2013-328/
  
  * LEGISLATIE NATIONALA PENTRU TURISM
  Ordinul nr. 65/2013 pentru aprobarea Normelor metodologice privind eliberarea certificatelor de clasificare a structurilor de primire turistice cu funcţiuni de cazare şi alimentaţie publică, a licenţelor şi brevetelor de turism.
  
  Hotărârea Guvernului 121/2013 privind eliberarea certificatelor de clasificare, a licenţelor şi brevetelor de turism.
  
  HG 1267/18.12.2010 privind eliberarea certificatelor de clasificare, a licentelor si brevetelor de turism.
  
  Ordinul Ministrului Transporturilor, Constructiilor si Turismului nr. 516/2005 pentru aprobarea contractului-cadru de comercializare a pachetelor de servicii turistice.
  
  Ordinul Ministrului Turismului nr. 235/2001 privind asigurarea turistilor în cazul insolvabilitatii sau falimentului agentiei de turism.
  
  OG 107/1999 republicată privind activitatea de comercializare a pachetelor de servicii turistice.`}
        </InfoText>

        <SectionTitle variant="h2">Protecția datelor personale</SectionTitle>
        <InfoText variant="body1">
          {`* NOTA DE INFORMARE PRIVIND PROTECTIA DATELOR PERSONALE
  Conform cerinţelor Legii nr. 677/2001 pentru protecţia persoanelor cu privire la prelucrarea datelor cu caracter personal şi libera circulaţie a acestor date, modificată şi completată şi ale Legii nr. 506/2004 privind prelucrarea datelor cu caracter personal şi protecţia vieţii private în sectorul comunicaţiilor electronice Agenția Pelerinul Creștin, cu sediul in Baia Mare, Bd.Traian nr.1B, reprezentata prin Pr. dr. Vana Valeriu Mircea, in calitate de Director, are obligaţia de a administra în condiţii de siguranţă şi numai pentru scopurile specificate, datele personale pe care ni le furnizaţi despre dumneavoastră, un membru al familiei dumneavoastră ori o altă persoană.
  
  Scopul colectării datelor este:promovarea de servicii si produse adresate direct clientilor personae fizice si promovarea ofertelor prin mijloace de marketing directe.
  Sunteţi obligat(ă) să furnizaţi datele, acestea fiind necesare procesarii soliciarii dvs.. Refuzul dvs. Determină imposibilitatea concretizării serviciului solicitat.
  Informaţiile înregistrate sunt destinate utilizării de către operator și sunt comunicate numai următorilor destinatari : furnizorilor de servicii turistice ai Agenției Pelerinul Creștin.
  
  Conform Legii nr. 677/2001, beneficiaţi de dreptul de acces, de intervenţie asupra datelor, dreptul de a nu fi supus unei decizii individuale şi dreptul de a vă adresa justiţiei. Totodată, aveţi dreptul să vă opuneţi prelucrării datelor personale care vă privesc şi să solicitaţi ştergerea datelor*. Pentru exercitarea acestor drepturi, vă puteţi adresa cu o cerere scrisă, datată şi semnată la sediul Agenției Pelerinul Creștin, adresată către dl. Vana Valeriu Mircea,directorul agenției. De asemenea, vă este recunoscut dreptul de a vă adresa justiţiei.
  Datele dumneavoastră vor fi transferate în țara care face obiectul contractului de comercializare a pachetelor de servicii turistice, în vederea finalizării serviciului contractat (rezervarea spațiilor de cazare, a serviciilor de transport, masă, etc.).
  
  Dacă unele din datele despre dumneavoastră sunt incorecte, vă rugăm să ne informaţi cât mai curând posibil.
  
  Observaţie:
  *orice persoană are dreptul de a se opune, pentru motive legitime, la prelucrarea datelor ce o privesc. Acest drept de opoziţie poate fi exclus pentru anumite prelucrări prevăzute de lege (de ex.: prelucrări efectuate de serviciile financiare şi fiscale, de poliţie, justiţie, securitate socială). Prin urmare, această menţiune nu poate figura dacă prelucrarea are un caracter obligatoriu;
  *orice persoană are, de asemenea, dreptul de a se opune, în mod gratuit şi fără nici o justificare, la prelucrarea datelor sale personale în scopuri de marketing direct.`}
        </InfoText>

        <SectionTitle variant="h2">Condiții asigurare medicală</SectionTitle>
        <InfoText variant="body1">
          {`* CONDIȚII ASIGURARE MEDICALĂ DE CĂLĂTORIE INCLUSĂ ÎN PACHETUL DE PELERINAJ EXTERN:
  - se poate încheia DOAR pentru persoanele care pot dovedi prin CARTE DE IDENTITATE ca au domiciliul sau reședința in ROMANIA, vârsta până la 80 ani. Pentru persoanele care nu se încadrează în cele menționate mai sus NU SE POATE ÎNTOCMI ASIGURAREA MEDICALĂ DE CĂLĂTORIE.`}
        </InfoText>
      </InfoSection>
    </Container>
  );
};

export default UsefulInfo;
