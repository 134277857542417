import { Route } from "react-router-dom";
import ListaPelerinaje from "./index";
import PelerinajCappadocia from "./PelerinajCappadocia";
import PelerinajAthos from "./PelerinajAthos";
import PelerinajGrecia from "./PelerinajGrecia";
import PelerinajEgipt from "./PelerinajEgipt";
import PelerinajItalia from "./PelerinajItalia";

// Import internal pilgrimages
import PelerinajRomaniaGrecia from "./PelerinajRomaniaGrecia";
import PelerinajBanatCazane from "./PelerinajBanatCazane";
import PelerinajBucuresti from "./PelerinajBucuresti";
import PelerinajTransilvania from "./PelerinajTransilvania";
import PelerinajMoldovaBucovina from "./PelerinajMoldovaBucovina";
import PelerinajIsrael from "./PelerinajIsrael";

export const pelerinajRoutes = [
  <Route
    key="lista-pelerinaje"
    path="/pelerinaje"
    element={<ListaPelerinaje />}
  />,

  // External pilgrimages
  <Route
    key="cappadocia"
    path="/pelerinaje/cappadocia"
    element={<PelerinajCappadocia />}
  />,
  <Route key="athos" path="/pelerinaje/athos" element={<PelerinajAthos />} />,
  <Route
    key="grecia"
    path="/pelerinaje/grecia"
    element={<PelerinajGrecia />}
  />,
  <Route key="egipt" path="/pelerinaje/egipt" element={<PelerinajEgipt />} />,
  <Route
    key="italia"
    path="/pelerinaje/italia"
    element={<PelerinajItalia />}
  />,
  <Route
    key="israel"
    path="/pelerinaje/israel"
    element={<PelerinajIsrael />}
  />,

  // Internal pilgrimages
  <Route
    key="romania-grecia"
    path="/pelerinaje/romania-grecia"
    element={<PelerinajRomaniaGrecia />}
  />,
  <Route
    key="banat-cazane"
    path="/pelerinaje/banat-cazane"
    element={<PelerinajBanatCazane />}
  />,
  <Route
    key="bucuresti"
    path="/pelerinaje/bucuresti"
    element={<PelerinajBucuresti />}
  />,
  <Route
    key="transilvania"
    path="/pelerinaje/transilvania"
    element={<PelerinajTransilvania />}
  />,
  <Route
    key="moldova"
    path="/pelerinaje/moldova"
    element={<PelerinajMoldovaBucovina />}
  />,
];

export default pelerinajRoutes;
