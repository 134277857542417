import React from "react";
import { useNavigate } from "react-router-dom";
import {
  Card,
  CardContent,
  CardMedia,
  Typography,
  Grid,
  Container,
  Box,
} from "@mui/material";
import { CustomOutlinedButton } from "./OutlinedButton";

const AdventureCard = ({ image, title, description, path }) => {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate(path);
  };
  return (
    <Card
      sx={{
        display: "flex",
        flexDirection: { xs: "column", md: "row" },
        width: "100%",
        borderRadius: "8px",
        boxShadow: 0,
        border: "1px solid #800020",
        background: "#800020",
        p: 1,
      }}
    >
      <CardMedia
        component="img"
        sx={{
          width: { xs: "100%", md: 300 },
          height: { xs: 200, md: 200 },
          borderRadius: "8px",
          border: "1px solid white",
          objectFit: "cover",
        }}
        image={image}
        alt={title}
      />
      <CardContent
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-start",
          alignContent: "flex-start",
          textAlign: "left",
          position: "relative",
          height: { xs: "180px", md: "200px" },
          width: "100%",
          p: { xs: 2, md: 3 },
        }}
      >
        <Box>
          <Typography
            variant="h6"
            component="div"
            gutterBottom
            sx={{
              fontFamily: "Inter",
              color: "white",
              fontSize: { xs: "1.1rem", md: "1.25rem" },
            }}
          >
            {title}
          </Typography>
          <Typography
            variant="body2"
            color="white"
            sx={{
              fontFamily: "Inter",
              fontSize: { xs: "0.875rem", md: "0.875rem" },
            }}
          >
            {description}
          </Typography>
        </Box>
        <CustomOutlinedButton
          onClick={handleClick}
          text="Mai mult"
          sx={{
            position: "absolute",
            bottom: 15,
            left: { xs: "50%", md: 24 },
            transform: { xs: "translateX(-50%)", md: "none" },
          }}
        />
      </CardContent>
    </Card>
  );
};

const App = () => {
  const adventures = [
    {
      image: "/images/IMG_5650.JPG",
      title: "Despre Noi",
      description: "Să descoperim împreună Romania creștină.",
      path: "/despre-noi",
    },
    {
      image: "/images/IMG_5653.JPG",
      title: "Pelerinaje",
      path: "/pelerinaje",
      description:
        "Un pelerinaj are la bază activitățile mântuitoare și sfințitoare..",
    },
  ];

  return (
    <Container maxWidth="lg">
      <Box
        sx={{
          padding: { xs: "20px", md: "40px" },
          textAlign: "left",
          width: "100%",
          minHeight: "500px",
          marginTop: { xs: "40px", md: "80px" },
        }}
      >
        <Typography
          variant="h4"
          component="h1"
          gutterBottom
          sx={{
            pt: { xs: 3, md: 7 },
            fontFamily: "Libre Baskerville",
            fontWeight: 700,
            fontSize: { xs: "1.75rem", md: "2.125rem" },
          }}
        >
          Despre - Agenția Pelerinul Creștin
        </Typography>
        <Typography
          variant="body2"
          component="p"
          gutterBottom
          sx={{
            pb: { xs: 3, md: 5 },
            fontFamily: "Inter",
            fontSize: { xs: "0.875rem", md: "1rem" },
            maxWidth: "800px",
          }}
        >
          Scopul nostru este de a deschide porțile către o călătorie a
          sufletului, în care fiecare pas pe pământ sfânt devine un pas mai
          aproape de Dumnezeu. Prin pelerinajele noastre, nu doar coordonăm o
          călătorie fizică, ci oferim o cale spre o întâlnire profundă cu
          divinul.
        </Typography>

        <Grid container spacing={{ xs: 2, md: 4 }} justifyContent="center">
          {adventures.map((adventure, index) => (
            <Grid item xs={12} md={6} key={index}>
              <AdventureCard
                image={adventure.image}
                title={adventure.title}
                description={adventure.description}
                path={adventure.path}
              />
            </Grid>
          ))}
        </Grid>
      </Box>
    </Container>
  );
};

export default App;
