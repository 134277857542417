import React from "react";
import { Typography } from "@mui/material";
import {
  StyledContainer,
  PageTitle,
  ContentSection,
  StyledList,
  InfoBox,
  textStyles,
} from "../../components/common/StyledComponents";

const PelerinajBucuresti = () => {
  return (
    <StyledContainer>
      <PageTitle>Pelerinaj duhovnicesc în București și împrejurimi</PageTitle>

      <ContentSection title="Informații Principale">
        <InfoBox>
          <Typography sx={textStyles.regular}>
            Perioada: în curs de actualizare
          </Typography>
          <Typography sx={textStyles.regular}>
            Plecare: Baia Mare, de la Catedrala Episcopală Sf. Treime, bd.
            Unirii nr.17, ora 4:00
          </Typography>
        </InfoBox>
      </ContentSection>

      <ContentSection title="Obiective Turistice">
        <Typography sx={textStyles.regular}>
          Vom face popasuri duhovnicești la:
        </Typography>
        <StyledList>
          <li>Catedrala Patriarhală</li>
          <li>Catedrala Mântuirii Neamului</li>
          <li>Mănăstirea Ghighiu</li>
          <li>Mănăstirea Pissiota</li>
          <li>Mănăstirea Plumbuita</li>
          <li>Mănăstirea Antim</li>
          <li>Mănăstirea Snagov</li>
          <li>Mănăstirea Căldărușani</li>
          <li>Mănăstirea Pasărea</li>
          <li>Mănăstirea Cernica</li>
          <li>Mănăstirea Darvari</li>
          <li>Mănăstirea Radu Vodă</li>
          <li>Biserica Stavropoleos</li>
          <li>
            Biserica Sf. Nicolae din Drăgănescu pictată de părintele Arsenie
            Boca
          </li>
        </StyledList>
      </ContentSection>

      <ContentSection title="Servicii Incluse">
        <StyledList>
          <li>Transport cu autocar clasificat</li>
          <li>3 nopți de cazare</li>
          <li>1 masă pe zi (prânz sau cină)</li>
          <li>Ghid de turism și preot însoțitor</li>
        </StyledList>
      </ContentSection>

      <ContentSection title="Servicii Neincluse">
        <StyledList>
          <li>Intrările la mănăstiri și obiective turistice și culturale</li>
        </StyledList>
      </ContentSection>

      <ContentSection title="Tarif">
        <Typography sx={textStyles.regular}>În curs de actualizare</Typography>
      </ContentSection>

      <ContentSection title="Informații Înscriere">
        <Typography sx={textStyles.regular}>
          Informații și înscrieri la sediul agenției sau la numărul de telefon:
          0755 182 802!
        </Typography>
        <StyledList>
          <li>
            Confirmarea locului se face în 5 zile de la data înscrierii
            printr-un avans
          </li>
          <li>
            Locurile în autocar se distribuie în ordinea achitării avansului
          </li>
        </StyledList>
      </ContentSection>

      <ContentSection title="Note Importante">
        <StyledList>
          <li>
            Minorii trebuie sa calatoreasca cu cel putin un adult insotitor
          </li>
          <li>
            Programul actiunii poate fi modificat de catre insotitorul de grup,
            ca ordine de desfasurare sau se pot inlocui unele obiective sau
            hoteluri in functie de anumite situatii speciale
          </li>
          <li>
            Distributia camerelor la hotel se face de catre receptia acestuia;
            problemele legate de amplasarea sau aspectul camerei se rezolva de
            catre turist direct la receptie, asistat de insotitorul de grup
          </li>
          <li>
            Agentia nu se obliga sa gaseasca partaj persoanelor care calatoresc
            singure
          </li>
        </StyledList>
      </ContentSection>

      <ContentSection title="Pregătirea Duhovnicească pentru Pelerinaj">
        <Typography sx={{ ...textStyles.regular, mb: 2 }}>
          Vă sfătuim cu părintească grijă:
        </Typography>
        <StyledList>
          <li>
            să vă spovediţi şi să luaţi binecuvântarea duhovnicului dvs. pentru
            a face acest Pelerinaj pe urmele Sfinților
          </li>
          <li>să iertaţi pe cei cu care aţi avut conflicte şi supărări</li>
          <li>să citiţi în această perioadă din viețile Sfinților, acatiste</li>
          <li>
            Să pregătiţi de acasă pomelnicele cu numele celor apropiaţi –
            pomelnice pe care doriţi să le lăsaţi în bisericile importante din
            Grecia (să scrieţi întotdeauna numele de botez a celui pomenit - cu
            majuscule, fără diminutive)
          </li>
          <li>
            să pregătiţi batiste noi sau mici obiecte pe care le veţi atinge de
            Icoane făcătoare de minuni și de raclele cu Sfintele Moaște
          </li>
        </StyledList>
      </ContentSection>

      <ContentSection title="Observații Importante">
        <Typography sx={{ ...textStyles.regular, mb: 2 }}>
          SĂ LUĂM AMINTE:
        </Typography>
        <Typography sx={textStyles.regular}>
          Pelerinajul nu este excursie sau concediu, ceea ce înseamnă că scopul
          este diferit de al acestora. Un pelerin datorează a pleca la drum cu
          inima dornică de a călători spre Dumnezeu, Care poate fi aflat mai
          aproape în anumite locuri:
        </Typography>
        <StyledList>
          <li>
            Pelerinajul înseamnă, de asemenea, nevoință și asceză, înfruntarea
            lipsurilor și a ispitelor, care pot proveni în anumite situații și
            respectrea postului (Miercurea, Vinerea si celalalte posturi de
            peste an)
          </li>
          <li>
            Mănăstirea este locul de nevoință prin excelență, iar cei care merg
            acolo își asumă faptul că vor intra și ei în rânduiala mănăstirii,
            pentru a se alătura monahilor, fie chiar pentru puțină vreme, în
            încercarea lor de a-L căuta pe Dumnezeu și a intra în comuniune cu
            El
          </li>
          <li>
            Mersul pe jos în cazul unor mănăstiri se încadrează tot în această
            nevoință, care în anumite situații este inevitabil. Așadar,
            pelerinii trebuie să se asigure că au condiția morală și fizică
            pentru a parcurge distanțe de mers pe jos
          </li>
          <li>
            Cei care au diverse afecțiuni ale sănătății, sunt datori să le
            menționeze la înscriere, pentru a primi sfaturile și îndrumările
            angajaților Agenției și pentru a nu pune întreg grupul în fața
            faptului împlinit de a avea nevoie de asistență medicală
            specializată sau de a renunța la anumite vizite
          </li>
          <li>
            Orice nelămuriri trebuie adresate agenției de pelerinaj, înainte de
            înscriere, pentru a fi evitate neînțelegerile ulterioare
          </li>
          <li>
            De asemenea, este bine de avut în vedere faptul că în program pot
            interveni modificări obiective, ca urmare a condițiilor de trafic,
            sau chiar a programului mănăstirilor, care diferă de la una la
            cealaltă
          </li>
          <li>
            Pelerinii sunt rugați să transporte cu ei un singur bagaj personal,
            de preferință rucsac, pentru a nu fi puși în dificultate atunci când
            va trebui să se deplaseze
          </li>
        </StyledList>
      </ContentSection>

      <Typography sx={{ ...textStyles.bold, mb: 2, textAlign: "center" }}>
        Vă așteptăm să fim împreună pelerini spre mântuire!
      </Typography>
    </StyledContainer>
  );
};

export default PelerinajBucuresti;
