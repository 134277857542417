import React from "react";
import { Typography, Box, Container } from "@mui/material";

const QuoteSection = () => {
  return (
    <Box
      sx={{
        width: "100%",
        py: 8,
        position: "relative",
        "&::before": {
          content: '""',
          position: "absolute",
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          background: `
            linear-gradient(
              to right,
              rgba(255, 247, 230, 0.8) 0%,
              rgba(255, 245, 220, 0.8) 35%,
              rgba(255, 245, 220, 0.8) 65%,
              rgba(255, 247, 230, 0.8) 100%
            ),
            repeating-linear-gradient(
              0deg,
              transparent,
              transparent 50px,
              rgba(177, 151, 119, 0.05) 50px,
              rgba(177, 151, 119, 0.05) 51px
            ),
            repeating-linear-gradient(
              90deg,
              transparent,
              transparent 50px,
              rgba(177, 151, 119, 0.05) 50px,
              rgba(177, 151, 119, 0.05) 51px
            )
          `,
          boxShadow: `
            inset 0 0 30px rgba(177, 151, 119, 0.2),
            inset 0 0 60px rgba(177, 151, 119, 0.1),
            0 0 10px rgba(177, 151, 119, 0.1)
          `,
          zIndex: -1,
        },
      }}
    >
      <Container maxWidth="lg">
        <Box
          sx={{
            position: "relative",
            "&::before": {
              content: '""',
              position: "absolute",
              top: 0,
              left: -20,
              right: -20,
              bottom: 0,
              background: "rgba(255, 250, 240, 0.5)",
              filter: "blur(10px)",
              zIndex: -1,
            },
          }}
        >
          <Typography
            variant="h4"
            component="h2"
            sx={{
              fontWeight: 600,
              textAlign: "center",
              fontFamily: "Cinzel",
              color: "#BE9257",
              mb: 6,
              textShadow: "1px 1px 2px rgba(190, 146, 87, 0.2)",
              fontSize: { xs: "1.75rem", md: "2.25rem" },
              letterSpacing: "0.02em",
            }}
          >
            Pelerinajul ca mod de viață
          </Typography>

          <Box
            sx={{
              display: "grid",
              gridTemplateColumns: { xs: "1fr" },
              gap: 4,
              mb: 6,
              p: 3,
              borderRadius: 2,
              background: "rgba(255, 250, 240, 0.7)",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: { xs: "column", md: "row" },
                gap: 4,
                alignItems: { xs: "center", md: "flex-start" },
              }}
            >
              <Box
                component="img"
                src="/images/Valeriu.jpeg"
                alt="Părintele VALERIU"
                sx={{
                  width: { xs: "200px", md: "160px" },
                  height: "auto",
                  objectFit: "cover",
                }}
              />
              <Box>
                <Typography
                  variant="body1"
                  sx={{
                    whiteSpace: "pre-line",
                    textAlign: "justify",
                    lineHeight: 1.8,
                    fontFamily: "Cormorant Garamond",
                    color: "#800020",
                    fontSize: { xs: "16px", md: "18px" },
                    fontWeight: 500,
                    letterSpacing: "0.01em",
                  }}
                >
                  {`,,Scopul duhovnicesc al agentiei este de a deschide porțile către o călătorie a sufletului, în care fiecare pas pe pământ sfânt devine un pas mai aproape de Dumnezeu. Prin pelerinajele pe care le organizezăm, agenția nu doar coordonează o călătorie fizică, ci oferă o cale spre o întâlnire profundă cu divinul, cu istoria sacră și cu propria credință.

                  Agenția are menirea de a facilita un pelerinaj al inimii, un drum al pocăinței, al rugăciunii și al curățirii sufletești. Fiecare loc vizitat, fie că este un sanctuar, o biserică sau o icoană făcătoare de minuni, devine un altar al reconcilierii, unde credincioșii pot să-și depună poverile, să ceară îndurare și să primească lumină.`}
                </Typography>
              </Box>
            </Box>

            <Box>
              <Typography
                variant="body1"
                sx={{
                  whiteSpace: "pre-line",
                  textAlign: "justify",
                  lineHeight: 1.8,
                  fontFamily: "Cormorant Garamond",
                  color: "#800020",
                  fontSize: { xs: "16px", md: "18px" },
                  fontWeight: 500,
                  letterSpacing: "0.01em",
                }}
              >
                {`Prin pelerinajele organizate, agenția își propune să fie un instrument al harului, să mijlocească întâlnirea dintre credincios și sfințenie, să ofere trăirea unor momente de revelație, pace și întărire spirituală. Fiecare călătorie devine un drum de întoarcere spre sine și spre izvorul credinței, un prilej de a reînnoi legământul cu Dumnezeu și de a redescoperi bucuria prezenței divine în viața de zi cu zi.

                Astfel, Agenția Pelerinul Creștin își asumă o misiune profund spirituală: să fie un călăuzitor pe calea credinței, un purtător al rugăciunii și un izvor de speranță, oferind fiecărui pelerin ocazia de a se regăsi în lumină, în har și în iubirea lui Dumnezeu."`}
              </Typography>
            </Box>
          </Box>

          <Box
            sx={{
              width: "100%",
              height: "1px",
              background:
                "linear-gradient(90deg, transparent, #BE9257 50%, transparent)",
              my: 4,
            }}
          />

          <Typography
            variant="body1"
            sx={{
              whiteSpace: "pre-line",
              textAlign: "center",
              lineHeight: 1.7,
              fontFamily: "EB Garamond",
              fontStyle: "italic",
              color: "#800020",
              fontSize: "16px",
              fontWeight: 500,
            }}
          >
            {`Vă așteptăm să fim împreună pelerini spre mântuire! Slavă și mulțumire pentru toate!

Pr. Dr. Valeriu Mircea Vana
Manager Agenția Pelerinul Creștin`}
          </Typography>
        </Box>
      </Container>
    </Box>
  );
};

export default QuoteSection;
