import React from "react";
import { Card, CardContent, Typography, Container, Box } from "@mui/material";

const PelerinajEgipt = () => {
  return (
    <Container className="py-8">
      <Typography variant="h3" className="mb-6 text-blue-900 font-bold">
        Pelerinaj în Egiptul Creștin
      </Typography>

      <Card className="mb-6">
        <CardContent>
          <Typography variant="h5" className="mb-4 text-blue-800 font-bold">
            Informații Principale
          </Typography>
          <Box className="space-y-3">
            <Typography>
              <span className="font-semibold">Status:</span> În curs de
              actualizare
            </Typography>
            <Typography>
              Detaliile acestui pelerinaj vor fi anunțate în curând
            </Typography>
          </Box>
        </CardContent>
      </Card>
    </Container>
  );
};

export default PelerinajEgipt;
