import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  AppBar,
  Toolbar,
  Button,
  Container,
  Box,
  IconButton,
  Menu,
  MenuItem,
  useTheme,
  useMediaQuery,
  Drawer,
  List,
  ListItem,
  ListItemText,
  ListItemButton,
  styled,
} from "@mui/material";
import { Menu as MenuIcon, ChevronDown } from "lucide-react";
import { CustomOutlinedButton } from "./OutlinedButton";

// Custom styled components
const NavButton = styled(Button)(({ theme }) => ({
  outline: "none",
  userSelect: "none",
  borderRadius: "9999px",
  background: "transparent",
  border: 0,
  padding: "8px 12px",
  fontSize: "14px",
  lineHeight: 1,
  cursor: "pointer",
  transition: "color 0.2s ease, background 0.2s ease",
  color: "var(--ds-gray-900)",
  fontFamily: "var(--font-sans)",
  textTransform: "none",
  "&:hover": {
    background: "rgba(0, 0, 0, 0.04)",
  },
  "& .MuiButton-endIcon": {
    marginLeft: 4,
  },
}));

const StyledMenu = styled(Menu)({
  "& .MuiPaper-root": {
    marginTop: 8,
    minWidth: 180,
  },
});

const StyledMenuItem = styled(MenuItem)({
  fontSize: "14px",
  padding: "8px 16px",
});

const navConfig = [
  {
    label: "Despre Noi",
    href: "/despre-noi",
  },
  {
    label: "Cuvântul Ierarhului",
    href: "/cuvantul-ierarhului",
  },
  {
    label: "Pelerinaje",
    href: "/pelerinaje",
  },
  {
    label: "Informatii Utile",
    href: "/informatii-utile",
  },
];
const navConfigMobile = [
  {
    label: "Despre Noi",
    href: "/despre-noi",
  },
  {
    label: "Cuvântul Ierarhului",
    href: "/cuvantul-ierarhului",
  },
  {
    label: "Pelerinaje",
    href: "/pelerinaje",
  },
  {
    label: "Informatii Utile",
    href: "/informatii-utile",
  },
  {
    label: "Contact",
    href: "/contact",
  },
];

// NavMenu Component
const NavMenu = ({ label, href, items }) => {
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState(null);
  const hasItems = Array.isArray(items) && items.length > 0;

  const handleClick = (event) => {
    if (hasItems) {
      setAnchorEl(event.currentTarget);
    } else {
      navigate(href);
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleMenuItemClick = (href) => {
    navigate(href);
    handleClose();
  };

  return (
    <Box sx={{ display: "inline-block" }}>
      <NavButton
        color="inherit"
        onClick={handleClick}
        endIcon={hasItems ? <ChevronDown style={{ height: "14px" }} /> : null}
        sx={{ mx: 1 }}
      >
        {label}
      </NavButton>
      {hasItems && (
        <StyledMenu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          {items.map((item) => (
            <StyledMenuItem
              key={item}
              onClick={() => handleMenuItemClick(item.href)}
            >
              {item.label}
            </StyledMenuItem>
          ))}
        </StyledMenu>
      )}
    </Box>
  );
};

const Navbar = () => {
  const [mobileOpen, setMobileOpen] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const navigate = useNavigate();

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const handleNavigate = (href) => {
    navigate(href);
    setMobileOpen(false);
  };
  const handleLogoClick = () => {
    navigate("/");
  };
  // Mobile drawer content
  const drawer = (
    <Box sx={{ width: 250 }}>
      <List>
        {navConfigMobile.map(({ label, href, items }) => {
          const hasItems = Array.isArray(items) && items.length > 0;
          return (
            <ListItem key={label} disablePadding>
              <ListItemButton onClick={() => handleNavigate(href)}>
                <ListItemText primary={label} />
              </ListItemButton>
              {hasItems && (
                <List sx={{ pl: 2 }}>
                  {items.map((item) => (
                    <ListItem key={item.label} disablePadding>
                      <ListItemButton onClick={() => handleNavigate(item.href)}>
                        <ListItemText primary={item.label} />
                      </ListItemButton>
                    </ListItem>
                  ))}
                </List>
              )}
            </ListItem>
          );
        })}
      </List>
    </Box>
  );

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar
        position="fixed"
        elevation={1}
        sx={{
          background: "#800D0A",
          boxShadow: "0 1px 0 0 rgba(0, 0, 0, 0.1)",
          color: "white",
          //  height: "64px",
        }}
      >
        <Container maxWidth="xl">
          <Toolbar>
            {isMobile ? (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  width: "100%",
                  alignItems: "center",
                }}
              >
                <img
                  src="./images/Logo_white.png"
                  alt="logo"
                  style={{ height: "55px", width: "55px" }}
                  onClick={handleLogoClick}
                />
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  <IconButton
                    color="inherit"
                    aria-label="open drawer"
                    edge="start"
                    onClick={handleDrawerToggle}
                    sx={{ ml: 2 }}
                  >
                    <MenuIcon />
                  </IconButton>
                </Box>
              </Box>
            ) : (
              <>
                <img
                  src="./images/Logo_white.png"
                  alt="logo"
                  style={{ height: "55px", width: "55px" }}
                  onClick={handleLogoClick}
                />
                <Box sx={{ flexGrow: 1 }}>
                  {navConfig.map((item) => (
                    <NavMenu key={item.label} {...item} />
                  ))}
                </Box>
                <CustomOutlinedButton
                  onClick={() => handleNavigate("/contact")}
                  size="small"
                  text="Contact"
                />
              </>
            )}
          </Toolbar>
        </Container>
      </AppBar>

      <Drawer
        variant="temporary"
        anchor="left"
        open={mobileOpen}
        onClose={handleDrawerToggle}
        ModalProps={{
          keepMounted: true,
        }}
        sx={{
          display: { xs: "block", md: "none" },
          "& .MuiDrawer-paper": { boxSizing: "border-box", width: 200 },
          fontFamily: "Inter",
        }}
      >
        {drawer}
      </Drawer>
    </Box>
  );
};

export default Navbar;
