import React, { useState } from "react";
import {
  Container,
  Typography,
  TextField,
  Box,
  InputAdornment,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Avatar,
  AvatarGroup,
} from "@mui/material";

const AddIcon = () => {
  <svg
    width="15"
    height="15"
    viewBox="0 0 15 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.49991 0.876892C3.84222 0.876892 0.877075 3.84204 0.877075 7.49972C0.877075 11.1574 3.84222 14.1226 7.49991 14.1226C11.1576 14.1226 14.1227 11.1574 14.1227 7.49972C14.1227 3.84204 11.1576 0.876892 7.49991 0.876892ZM1.82707 7.49972C1.82707 4.36671 4.36689 1.82689 7.49991 1.82689C10.6329 1.82689 13.1727 4.36671 13.1727 7.49972C13.1727 10.6327 10.6329 13.1726 7.49991 13.1726C4.36689 13.1726 1.82707 10.6327 1.82707 7.49972ZM7.50003 4C7.77617 4 8.00003 4.22386 8.00003 4.5V7H10.5C10.7762 7 11 7.22386 11 7.5C11 7.77614 10.7762 8 10.5 8H8.00003V10.5C8.00003 10.7761 7.77617 11 7.50003 11C7.22389 11 7.00003 10.7761 7.00003 10.5V8H4.50003C4.22389 8 4.00003 7.77614 4.00003 7.5C4.00003 7.22386 4.22389 7 4.50003 7H7.00003V4.5C7.00003 4.22386 7.22389 4 7.50003 4Z"
      fill="black"
      fillRule="evenodd"
      clipRule="evenodd"
    ></path>
  </svg>;
};
const RemoveIcon = () => {
  <svg
    width="15"
    height="15"
    viewBox="0 0 15 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.49991 0.876892C3.84222 0.876892 0.877075 3.84204 0.877075 7.49972C0.877075 11.1574 3.84222 14.1226 7.49991 14.1226C11.1576 14.1226 14.1227 11.1574 14.1227 7.49972C14.1227 3.84204 11.1576 0.876892 7.49991 0.876892ZM1.82707 7.49972C1.82707 4.36671 4.36689 1.82689 7.49991 1.82689C10.6329 1.82689 13.1727 4.36671 13.1727 7.49972C13.1727 10.6327 10.6329 13.1726 7.49991 13.1726C4.36689 13.1726 1.82707 10.6327 1.82707 7.49972ZM4.50003 7C4.22389 7 4.00003 7.22386 4.00003 7.5C4.00003 7.77614 4.22389 8 4.50003 8H10.5C10.7762 8 11 7.77614 11 7.5C11 7.22386 10.7762 7 10.5 7H4.50003Z"
      fill="black"
      fillRule="evenodd"
      clipRule="evenodd"
    ></path>
  </svg>;
};

const SearchIcon = () => {
  <svg
    width="15"
    height="15"
    viewBox="0 0 15 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10 6.5C10 8.433 8.433 10 6.5 10C4.567 10 3 8.433 3 6.5C3 4.567 4.567 3 6.5 3C8.433 3 10 4.567 10 6.5ZM9.30884 10.0159C8.53901 10.6318 7.56251 11 6.5 11C4.01472 11 2 8.98528 2 6.5C2 4.01472 4.01472 2 6.5 2C8.98528 2 11 4.01472 11 6.5C11 7.56251 10.6318 8.53901 10.0159 9.30884L12.8536 12.1464C13.0488 12.3417 13.0488 12.6583 12.8536 12.8536C12.6583 13.0488 12.3417 13.0488 12.1464 12.8536L9.30884 10.0159ZM4.25 6.5C4.25 6.22386 4.47386 6 4.75 6H6V4.75C6 4.47386 6.22386 4.25 6.5 4.25C6.77614 4.25 7 4.47386 7 4.75V6H8.25C8.52614 6 8.75 6.22386 8.75 6.5C8.75 6.77614 8.52614 7 8.25 7H7V8.25C7 8.52614 6.77614 8.75 6.5 8.75C6.22386 8.75 6 8.52614 6 8.25V7H4.75C4.47386 7 4.25 6.77614 4.25 6.5Z"
      fill="currentColor"
      fillRule="evenodd"
      clipRule="evenodd"
    ></path>
  </svg>;
};
const FAQSection = () => {
  const [expanded, setExpanded] = useState("panel1");
  const [searchTerm, setSearchTerm] = useState("");

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  const faqs = [
    {
      question: "Cum pot să rezerv un loc pentru pelerinaj?",
      answer:
        "Rezervarea locului pentru pelerinaj se poate face telefonic la numărul 0755182802 sau la sediul Agenției Pelerinul Creștin, bulevardul Traian 1B, Baia Mare, program LUNI - VINERI: orele 9-15. Important: Avansul se achită în maxim 5 zile de la înscriere, în caz contrar rezervarea se anulează.",
    },
    {
      question: "Care sunt modalitățile de plată disponibile?",
      answer:
        "Plata parțială sau integrală se poate face în următoarele moduri: 1) La sediul Agenției Pelerinul Creștin 2) Prin ordin de plată la orice sediu al Băncii Transilvania 3) Prin transfer bancar în conturile: LEI: RO91BTRLRONCRT0475085201 sau EURO: RO41BTRLEURCRT0475085201. Pentru plățile în lei ale pelerinajelor cu tarife în euro sau dolari, se achită contravaloarea la cursul BNR din ziua plății + 2% comision de risc valutar.",
    },
    {
      question: "Care sunt condițiile de retragere și rambursare?",
      answer:
        "Pentru plata avansului: avansul nu se restituie. Pentru plata integrală a pelerinajului: - Se returnează 50% din preț dacă renunțarea se face cu mai mult de 20 zile calendaristice înainte de plecare - Se reține 100% din preț dacă renunțarea se face într-un interval mai mic de 10 zile calendaristice înainte de plecare sau prin neprezentare la program.",
    },
    {
      question: "Cu cât timp înainte trebuie să achit integral pelerinajul?",
      answer:
        "Plata integrală trebuie efectuată cu 30 zile înainte de călătorie pentru pelerinajele externe și cu 15 zile înainte pentru pelerinajele interne.",
    },
    {
      question: "Ce informații trebuie să menționez la efectuarea plății?",
      answer:
        "La specificația plății trebuie să menționați: - pelerinajul și data acestuia - numărul de persoane participante și numele - localitatea de domiciliu. După efectuarea plății, trebuie să trimiteți copia documentului de plată pe WhatsApp la numărul 0755182802 sau pe email la pelerin.maramures@yahoo.com",
    },
    {
      question: "Cum se stabilesc locurile în autocar/microbuz?",
      answer:
        "Stabilirea locurilor în autocar sau microbuz se face în funcție de data confirmării locului prin achitarea avansului sau plata integrală. Cu cât confirmați mai repede prin plată, cu atât aveți prioritate la alegerea locului.",
    },
  ];

  const filteredFaqs = faqs.filter(
    (faq) =>
      faq.question.toLowerCase().includes(searchTerm.toLowerCase()) ||
      faq.answer.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <Container
      maxWidth="md"
      sx={{ py: 8, textAlign: "center", px: { xs: 3, md: 2 } }}
    >
      <Typography
        variant="h4"
        component="h1"
        align="center"
        sx={{
          pt: 7,
          fontFamily: "Libre Baskerville",
          fontWeight: 700,
        }}
      >
        Întrebări frecvente
      </Typography>

      <Typography
        variant="body2"
        component="p"
        gutterBottom
        sx={{ pb: 5, fontFamily: "Inter", mt: 1 }}
      >
        Aveți întrebări? Suntem aici să vă ajutăm.
      </Typography>

      <Box sx={{ mb: 8 }}>
        {filteredFaqs.map((faq, index) => (
          <Accordion
            key={index}
            expanded={expanded === `panel${index + 1}`}
            onChange={handleChange(`panel${index + 1}`)}
            sx={{
              border: "none",
              boxShadow: "none",
              "&:before": {
                display: "none",
              },
              "&:not(:last-child)": {
                borderBottom: 1,
                borderColor: "divider",
              },
            }}
          >
            <AccordionSummary
              expandIcon={
                expanded === `panel${index + 1}` ? (
                  <svg
                    width="15"
                    height="15"
                    viewBox="0 0 15 15"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M7.49991 0.876892C3.84222 0.876892 0.877075 3.84204 0.877075 7.49972C0.877075 11.1574 3.84222 14.1226 7.49991 14.1226C11.1576 14.1226 14.1227 11.1574 14.1227 7.49972C14.1227 3.84204 11.1576 0.876892 7.49991 0.876892ZM1.82707 7.49972C1.82707 4.36671 4.36689 1.82689 7.49991 1.82689C10.6329 1.82689 13.1727 4.36671 13.1727 7.49972C13.1727 10.6327 10.6329 13.1726 7.49991 13.1726C4.36689 13.1726 1.82707 10.6327 1.82707 7.49972ZM4.50003 7C4.22389 7 4.00003 7.22386 4.00003 7.5C4.00003 7.77614 4.22389 8 4.50003 8H10.5C10.7762 8 11 7.77614 11 7.5C11 7.22386 10.7762 7 10.5 7H4.50003Z"
                      fill="black"
                      fillRule="evenodd"
                      clipRule="evenodd"
                    ></path>
                  </svg>
                ) : (
                  <svg
                    width="15"
                    height="15"
                    viewBox="0 0 15 15"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M7.49991 0.876892C3.84222 0.876892 0.877075 3.84204 0.877075 7.49972C0.877075 11.1574 3.84222 14.1226 7.49991 14.1226C11.1576 14.1226 14.1227 11.1574 14.1227 7.49972C14.1227 3.84204 11.1576 0.876892 7.49991 0.876892ZM1.82707 7.49972C1.82707 4.36671 4.36689 1.82689 7.49991 1.82689C10.6329 1.82689 13.1727 4.36671 13.1727 7.49972C13.1727 10.6327 10.6329 13.1726 7.49991 13.1726C4.36689 13.1726 1.82707 10.6327 1.82707 7.49972ZM7.50003 4C7.77617 4 8.00003 4.22386 8.00003 4.5V7H10.5C10.7762 7 11 7.22386 11 7.5C11 7.77614 10.7762 8 10.5 8H8.00003V10.5C8.00003 10.7761 7.77617 11 7.50003 11C7.22389 11 7.00003 10.7761 7.00003 10.5V8H4.50003C4.22389 8 4.00003 7.77614 4.00003 7.5C4.00003 7.22386 4.22389 7 4.50003 7H7.00003V4.5C7.00003 4.22386 7.22389 4 7.50003 4Z"
                      fill="black"
                      fillRule="evenodd"
                      clipRule="evenodd"
                    ></path>
                  </svg>
                )
              }
              sx={{
                padding: "16px 0",
                color: "black",
              }}
            >
              <Typography
                sx={{
                  fontSize: "1rem",
                  fontWeight: 500,
                  color: "text.primary",
                  fontFamily: "Inter",
                }}
              >
                {faq.question}
              </Typography>
            </AccordionSummary>
            <AccordionDetails sx={{ padding: "0 0 16px 0" }}>
              <Typography
                color="text.secondary"
                sx={{ fontFamily: "Inter", textAlign: "left" }}
              >
                {faq.answer}
              </Typography>
            </AccordionDetails>
          </Accordion>
        ))}
      </Box>
    </Container>
  );
};

export default FAQSection;
