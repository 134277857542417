import React from "react";
import { Route, Routes } from "react-router-dom";
import { CssBaseline } from "@mui/material";
import TermsAndConditions from "./components/TermsAndConditions";
import AboutUs from "./components/AboutUs";
import Contact from "./components/Contact";
import HeaderImage from "./components/Header";
import UsefulInfo from "./components/UsefulInfo";
import { pelerinajRoutes } from "./pages/Pelerinaje/routes";
import Navigation from "./components/Navigation";
import Navbar from "./components/Navbar";
import CuvantulIerarhului from "./pages/CuvantulIerarhului";

const App = () => {
  return (
    <div>
      <CssBaseline />
      <Navbar />
      <Routes>
        <Route path="/" element={<HeaderImage />} />
        <Route path="/despre-noi" element={<AboutUs />} />
        <Route path="/termeni-si-conditii" element={<TermsAndConditions />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/informatii-utile" element={<UsefulInfo />} />
        <Route path="/cuvantul-ierarhului" element={<CuvantulIerarhului />} />
        {pelerinajRoutes}
      </Routes>
    </div>
  );
};

export default App;
