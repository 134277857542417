// src/components/common/StyledComponents.js
import { styled } from "@mui/material/styles";
import { Container, Typography, Card, CardContent, Box } from "@mui/material";

export const StyledContainer = styled(Container)({
  maxWidth: "1200px",
  padding: "2rem 1rem",
});

export const PageTitle = styled(Typography)({
  fontSize: "2rem",
  fontWeight: 700,
  color: "#000",
  marginBottom: "80px",
  marginTop: "60px",
  textAlign: "center",
  fontFamily: "Libre Baskerville",
});

export const SectionTitle = styled(Typography)({
  fontSize: "1.5rem",
  paddingBottom: "10px",
  borderBottom: `2px solid #800020`,
  color: "black",
  marginBottom: "20px",
  fontFamily: "Libre Baskerville",
  fontWeight: "bold",
});

export const StyledCard = styled(Card)({
  marginBottom: "1.5rem",
  fontFamily: "Inter",
  boxShadow: "none",
  "&:last-child": {
    marginBottom: 0,
  },
});

export const StyledList = styled("ul")({
  listStyleType: "disc",
  paddingLeft: "1.5rem",
  fontFamily: "Inter",
  "& li": {
    marginBottom: "0.75rem",
    lineHeight: "1.5",
  },
});

export const InfoBox = styled(Box)({
  "& > *": {
    marginBottom: "0.5rem",
    gap: 10,
  },
});

export const ImportantNote = styled(Typography)({
  backgroundColor: "#FFF7C2",
  padding: "1rem",
  borderRadius: "0.5rem",
  marginTop: "1rem",
  fontSize: "0.875rem",
  fontFamily: "Inter",
  border: "1px solid #E2A336",
  color: "#800020",
  fontWeight: 500,
});

export const ContentSection = ({ title, children }) => (
  <StyledCard>
    <CardContent>
      <SectionTitle>{title}</SectionTitle>
      {children}
    </CardContent>
  </StyledCard>
);

// Custom text styles that can be applied to Typography components
export const textStyles = {
  regular: {
    fontFamily: "Inter",
  },
  bold: {
    fontFamily: "Inter",
    fontWeight: 600,
  },
  highlight: {
    fontFamily: "Inter",
    color: "#800020",
  },
};
