import {
  Box,
  Typography,
  Avatar,
  AvatarGroup,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import React from "react";
import { CustomContainedButton, CustomOutlinedButton } from "./OutlinedButton";
import About from "./About";
import Pelerinaje from "./Pelerinaje";
import FAQSection from "./FAQSection";
import { useNavigate } from "react-router-dom";

const HeaderImage = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isTablet = useMediaQuery(theme.breakpoints.between("sm", "md"));
  const navigate = useNavigate();

  const handleClick = (path) => {
    navigate(path);
  };
  const images = [
    "/images/IMG_5647.JPG",
    "/images/IMG_5648.JPG",
    "/images/IMG_5649.JPG",
  ];

  return (
    <Box
      sx={{
        width: "100%",
        overflow: "hidden",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Typography
        variant={isMobile ? "h4" : "h3"}
        sx={{
          pt: { xs: 10, sm: 15, md: 20 },
          fontFamily: "Cinzel",
          fontWeight: "500",
          width: { xs: "90%", sm: "80%", md: "800px" },
          textAlign: "center",
          fontSize: {
            xs: "1.75rem",
            sm: "2.25rem",
            md: "3rem",
          },
          px: { xs: 2, sm: 0 },
        }}
      >
        Agenția Pelerinul Creștin <br /> ,,Poartă spre suflet!"
      </Typography>

      <Typography
        variant="body2"
        sx={{
          pt: 2,
          pb: 5,
          fontFamily: "Inter",
          width: { xs: "90%", sm: "70%", md: "500px" },
          textAlign: "center",
          px: { xs: 2, sm: 0 },
          fontSize: { xs: "0.875rem", sm: "1rem" },
        }}
      >
        Este singura agenție de pelerinaje licențiată din Maramureș și Satu
        Mare, specializată în turism religios și de pelerinaj cu experiență de
        peste 7 ani.
      </Typography>

      <Box
        sx={{
          width: "100%",
          display: "flex",
          flexDirection: { xs: "column", sm: "row" },
          alignItems: "center",
          justifyContent: "center",
          gap: { xs: "16px", sm: "12px" },
          pb: { xs: 6, sm: 8, md: 12 },
        }}
      >
        <CustomContainedButton
          onClick={() => handleClick("/pelerinaje")}
          sx={{
            height: "40px",
            width: { xs: "200px!important", sm: "110px!important" },
          }}
          text="Pelerinaje"
        />
        <CustomOutlinedButton
          onClick={() => handleClick("/despre-noi")}
          sx={{
            height: "40px!important",
            width: { xs: "200px", sm: "110px" },
          }}
          text="Despre noi"
        />
      </Box>

      {/* Images Container */}
      <Box
        sx={{
          display: "flex",
          overflowX: "hidden",
          gap: { xs: 1, sm: 2 },
          padding: { xs: 1, sm: 2 },
          "&::-webkit-scrollbar": {
            height: "8px",
          },
          "&::-webkit-scrollbar-track": {
            background: "#f1f1f1",
          },
          "&::-webkit-scrollbar-thumb": {
            background: "#888",
            borderRadius: "4px",
          },
          "&::-webkit-scrollbar-thumb:hover": {
            background: "#555",
          },
        }}
      >
        {images.map((image, index) => (
          <Box
            key={index}
            sx={{
              minWidth: { xs: "300px", sm: "450px", md: "600px" },
              height: { xs: "250px", sm: "400px", md: "500px" },
              flexShrink: 0,
              position: "relative",
              borderRadius: "8px",
              overflow: "hidden",
            }}
          >
            <img
              src={image}
              alt={`Slide ${index + 1}`}
              style={{
                width: "100%",
                height: "100%",
                objectFit: "cover",
                display: "block",
              }}
            />
          </Box>
        ))}
      </Box>

      <About />
      <Box
        sx={{
          width: "100%",
          height: "1px",
          background:
            "linear-gradient(90deg, transparent, #BE9257 50%, transparent)",
          my: 4,
        }}
      />
      <Pelerinaje />
      <FAQSection />

      {/* FAQ Section */}
      <Box
        sx={{
          textAlign: "center",
          background: "#FEF8E8",
          width: "100%",
          minHeight: { xs: "350px", sm: "300px" },
          py: { xs: 4, sm: 0 },
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <AvatarGroup
          max={4}
          sx={{
            justifyContent: "center",
            mb: 2,
            "& .MuiAvatar-root": {
              width: { xs: 40, sm: 48 },
              height: { xs: 40, sm: 48 },
              border: 2,
              borderColor: "background.paper",
            },
          }}
        >
          <Avatar alt="Support 1" src="/images/valeriu.JPG" />
          <Avatar alt="Support 2" src="/images/IMG_5774.JPG" />
        </AvatarGroup>

        <Typography
          variant={isMobile ? "h5" : "h4"}
          component="h1"
          gutterBottom
          sx={{
            mb: 1,
            fontFamily: "Libre Baskerville",
            fontWeight: 700,
            px: { xs: 2, sm: 0 },
          }}
        >
          Mai aveți întrebări?
        </Typography>

        <Typography
          variant="body2"
          sx={{
            maxWidth: { xs: "90%", sm: "500px" },
            mx: "auto",
            fontFamily: "Inter",
            mb: 4,
            px: { xs: 2, sm: 0 },
            fontSize: { xs: "0.875rem", sm: "1rem" },
          }}
        >
          Nu găsiți răspunsul pe care îl căutați? Vă rugăm să discutați cu
          echipa noastră prietenoasă.
        </Typography>

        <CustomContainedButton
          onClick={() => handleClick("/contact")}
          text="Contact"
          sx={{
            width: { xs: "200px", sm: "auto" },
          }}
        />
      </Box>
    </Box>
  );
};

export default HeaderImage;
