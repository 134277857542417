import React from "react";
import { Typography } from "@mui/material";
import {
  StyledContainer,
  PageTitle,
  ContentSection,
  StyledList,
  InfoBox,
  ImportantNote,
  textStyles,
} from "../../components/common/StyledComponents";

const PelerinajCappadocia = () => {
  return (
    <StyledContainer>
      <PageTitle>Pelerinaj în Cappadocia și Asia Mică</PageTitle>

      <ContentSection title="Informații Principale">
        <InfoBox>
          <Typography sx={textStyles.regular}>
            Cu binecuvântarea și purtarea de grijă a Preasfințitului Părinte
            IUSTIN
          </Typography>
          <Typography sx={textStyles.regular}>
            <span className="font-bold">Preț:</span> 1100 EURO/ pers./ loc in
            camera dublă
          </Typography>
          <Typography sx={textStyles.regular}>
            <span className="font-bold">Supliment cameră single:</span> 300 EURO
          </Typography>
          <Typography sx={textStyles.regular}>
            <span className="font-bold">Durată:</span> 8 nopți
          </Typography>
        </InfoBox>
      </ContentSection>

      <ContentSection title="Servicii Incluse">
        <StyledList>
          <li>
            Transport cu avionul: Cluj Napoca – Istanbul , Istanbul – Nevsehir,
            Istanbul – Cluj Napoca
          </li>
          <li>Bagaj de cală 23 kg, bagaj de mână 10 kg (20x30x40 cm)</li>
          <li>Transport cu autocarul conform programului</li>
          <li>8 nopti cazare la hotel 4-5* clasificare locală</li>
          <li>Masă: mic dejun și cină</li>
          <li>
            Preot însoțitor-ghid de turism specializat în turism religios
            vorbitor de română
          </li>
          <li>Ghidul pelerinului, eșarfă, ecuson</li>
          <li>Asigurarea medicală</li>
        </StyledList>
        <ImportantNote>
          Asigurarea de sanatate se poate incheia DOAR pentru persoanele care
          pot dovedi prin CARTEA DE IDENTITATE ca au DOMICILIUL sau RESEDINTA in
          ROMANIA, vârsta până la 80 ani. Pentru persoanele care nu se
          incadreaza in cele mentionate mai sus, NU SE POATE INCHEIA ASIGURARE
          DE SANATATE.
        </ImportantNote>
      </ContentSection>

      <ContentSection title="Servicii Neincluse">
        <StyledList>
          <li>Taxe intrări obiective (aprox. 200 Euro/pers)</li>
          <li>Croazieră pe Bosfor (20 Euro/pers)</li>
          <li>Ghid local (40 Euro/pers)</li>
          <li>Transport aeroport (40 Euro/pers)</li>
        </StyledList>
      </ContentSection>

      <ContentSection title="Acte Necesare">
        <StyledList>
          <li>
            Carte de identitate sau paşaport valabil minim 6 luni de la data
            întoarcerii în ţară
          </li>
        </StyledList>
      </ContentSection>

      <ContentSection title="Detalii Plată">
        <InfoBox>
          <Typography sx={textStyles.regular}>
            Avans: 300 euro/persoană
          </Typography>
          <Typography sx={textStyles.regular}>
            Plata integrală cu 30 zile înainte de derularea pelerinajului, data
            limită 20 ianuarie 2025
          </Typography>
          <Typography sx={textStyles.regular}>
            Așezarea în autocar se face în ordinea înscrierilor!
          </Typography>
          <Typography sx={textStyles.regular}>
            Înscrierile se pot face la sediul agenției din bulevardul Traian nr.
            1B, Luni - Vineri: 9:00-15:00
          </Typography>
        </InfoBox>
      </ContentSection>

      <ContentSection title="Despre Cappadocia și Asia Mică">
        <Typography sx={{ ...textStyles.regular, mb: 3 }}>
          Cappadocia și Asia Mică sunt locuri încărcate de har și lumină, unde
          pelerinul este chemat să pășească cu inima deschisă spre o întâlnire
          cu adâncul sufletului și cu prezența divină. Aceste ținuturi nu sunt
          doar martore ale unei istorii milenare, ci și locuri unde Dumnezeu a
          lucrat tainic prin sfinți, pustnici și mucenici, lăsând urme vizibile
          în peisajul stâncos și tăcut al Cappadociei, dar și în ruinele sacre
          ale Asiei Mici.
        </Typography>

        <Typography sx={{ ...textStyles.regular, mb: 3 }}>
          În Cappadocia, te afli față în față cu măreția creației divine. Acolo,
          unde stâncile modelate de vânt și vreme se ridică asemenea unor
          catedrale naturale, simți cum sufletul se înalță dincolo de limitele
          acestei lumi. Peșterile și bisericile săpate în piatră de primii
          creștini sunt ca niște adăposturi tainice pentru rugăciune, unde
          liniștea desăvârșită îți permite să asculți glasul Domnului.
          Pătrunzând în aceste locuri, înțelegi că pelerinajul nu este doar o
          călătorie fizică, ci o trecere spirituală spre comuniunea cu Dumnezeu,
          prin retragerea în rugăciune și smerenie.
        </Typography>

        <Typography sx={{ ...textStyles.regular, mb: 3 }}>
          Asia Mică este leagănul multor sfinți și pământul unde Biserica s-a
          născut și s-a întărit prin sângele martirilor. Aici au propovăduit
          apostolii și s-au așezat primele comunități creștine, ocrotite de mâna
          lui Dumnezeu în mijlocul persecuțiilor. Efesul, unde Sfântul Apostol
          Pavel a predicat, și locul unde se spune că a trăit Fecioara Maria, te
          îndeamnă să meditezi la începuturile Bisericii și la iubirea
          jertfelnică a Mântuitorului.
        </Typography>
      </ContentSection>

      <ContentSection title="Obiective Turistice">
        <StyledList>
          <li>
            <strong>Edirne</strong> (fostul oraş Adrianopol, întemeiat de
            împăratul roman Hardrian. A fost capitala Imperiului Otoman între
            1365 - 1453. Aici s-a încheiat Pacea de la Adrianopol, tratatul
            ruso-turc din anul 1829 prin care s-a recunoscut independența
            Greciei, autonomia Serbiei și s-a desființat monopolul turcesc
            asupra comerțului Ţării Româneşti și Moldovei) – tur panoramic Podul
            Tunca şi Moscheea Selimiye Cami (construită între anii 1569 –1575 de
            către arhitectul Mimar Sinan la cerinţa sultanului Selim II, este
            cea mai mare moschee din Turcia) – Canakkale.
          </li>
          <li>
            <strong>Kusadasi</strong>. Se vizitează ruinele vechilor cetăti
            Troia I – IX (primul oraș a fost fondat în mileniul III î.Hr. în
            timpul Epocii Bronzului. Se păstrază inclusiv ruine din Troia VII,
            cea descrisa de Homer. Ultimul oraș de pe acest sit arheologic a
            fost Iliumul elenistic, fondat de romani în timpul domniei
            împăratului Augustus, care a decăzut după desemnarea
            Constantinopolului drept capitală a Imperiului Roman în sec.IV).
          </li>
          <li>
            <strong>Pergamul</strong> (oraș antic grecesc, devenit capitala
            Regatului Pergam în timpul perioadei elenistice 281-133 î.Hr.
            Pergamul este amintit în cartea Apocalipsei. Aici a fost inventat
            pergamentul, a cărui denumire provine de la numele orașului).
          </li>
          <li>
            <strong>Biserica Apocaliptica Curtea Roşie</strong> (aici a fost
            iniţial un templu păgân. În anul 92 d.Hr., în acest templu a a fost
            martirizat Sfântul Antipa, primul arhiepiscop al Pergamului, numit
            din porunca Sfântului Ioan. În primul secol d.Hr., biserica creștină
            din Pergam, adăpostită în interiorul clădirii principale a Basilicii
            Roșii, a fost una dintre Cele Șapte Biserici descrise în Apocalipsa
            2:12).
          </li>
          <li>
            <strong>Izmir</strong> (vechea Smyrna, cetatea păstorită de Sfântul
            Policarp, oraş fondat în sec. IV î.Hr., cetatea păstorită de Sfântul
            Policarp. Este al treilea oraș ca mărime în Turcia după Istanbul și
            Ankara. Aici s-au născut Homer și Aristotel Onassis).
          </li>
          <li>
            <strong>Cetatea Efes</strong> (oraşul antic Efes a fost întemeiat în
            sec.XI Î.Hr. de coloniştii ionieni, devenind în cursul timpului unul
            din cele mai dezvoltate centre religioase și comerciale. A suferit
            pierderi însemnate la cutremurele din anii 358 și 365 d.Hr., iar în
            sec. VII a început să decadă. Astăzi se pot vedea ruinele
            Bibliotecii lui Celsus, marelui teatru în care a predicat Sfântul
            Apostol Pavel, Biserica Maicii Domnului- în care s-a ţinut Sinodul
            III Ecumenic in anul 431).
          </li>
          <li>
            <strong>Casa și Izvoarele Maicii Domnului</strong> (conform
            tradiţiei creştine, Maica Domnului a fost adusă în Efes de către
            Sfântul Apostol Ioan Evanghelistul, la scurt timp după Învierea din
            morţi a Mântuitorului, aici urmând a locui până la Adormirea ei.
            Biserica datează din sec.VI-VII, dar fundaţiile casei au o origine
            mult mai veche – posibilitatea ca acestea să aparţină sec. I fiind
            foarte mare. Astăzi este biserică romano-catolică).
          </li>
          <li>
            <strong>Mormântul Sfântului Ioan Evanghelistul</strong> (ruinele
            impresionantei biserici bizantine din Efesînchinate Sfântului Ioan
            Teologul, marchează locul unde, potrivit traditiei, acesta ar fi
            fost înmormântat. Iniţial, în sec. IV-V a fost ridicată o biserică,
            pe locul mormântului Sfantului Ioan Teologul, apoi în sec. VI
            imparatul Iustinian a ridicat o biserică impunătoare pe locul celei
            vechi).
          </li>
          <li>
            <strong>Coloanele Templului zeiţei Artemis</strong> (una dintre cele
            7 minuni ale lumii, templu construit în sec. VI î.Hr, a fost
            incendiat în anul 356 î.Hr., de un cetățean din Efes cu numele de
            Herostrat, din dorința de a deveni celebru).
          </li>
          <li>
            <strong>Pamukkale</strong> (se traduce prin ''Castelul de bumbac''
            datorită teraselor calcaroase făcute din travertin, rocă sedimentară
            care s-a format de-a lungul a mii de ani prin depunerile de carbonat
            de calciu din apa celor 17 izvoare existente în zonă).
          </li>
          <li>
            În Pamukkale se vizitează Necropola si Cetatea Hierapolis (anticul
            oraş grecesc Ierapolis – Oraşul Sfânt, ridicat în regiunea
            izvoarelor cu apă caldă în vechea Frigia, se va urca la Biserica
            ridicată pe locul unde Sfântul Apostol Filip a fost martirizat, dar
            şi presupusul mormânt al Apostolului, nou descoperit de arheologii
            turci).
          </li>
          <li>
            <strong>Cappadocia</strong> (se află în estulAnatoliei, în centrul
            Turciei. Relieful constă dintr-un platou înalt de peste 1000 m
            altitudine, care este străpuns de vârfuri vulcanice dintre care
            Muntele Erciyes, în apropiere de Kayseri, este cel mai înalt vârf cu
            altitudinea de 3916 m).
          </li>
          <li>
            <strong>Muzeul în aer liber Goreme</strong> (muzeu în aer liber care
            adăposteşte manăstiri şi biserici paleocreştine săpate în tuf
            vulcanic, chilia în care a locuit Sfântul Vasile Cel Mare).
          </li>
          <li>
            <strong>Istanbul</strong> - Catedrala Sfânta Sofia, Palatul Topkapi
            (reşedinţa sultanilor, actualul Muzeu Naţional al Turciei, care
            adăposteşte exponate unice: mâna dreaptă a Sfântului Ioan
            Botezatorul, sabia lui Ştefan cel Mare), Moscheea Albastră, Basilica
            Cisterne, fostul Hipodrom.
          </li>
          <li>
            <strong>Mănăstirea Izvorul Tămăduirii</strong> și Biserica
            Românească cu hramul Sfânta Muceniţă Paraschevi, Sfinţii Martiri
            Brâncoveni şi Sfânta Arghira.
          </li>
          <li>
            <strong>Patriarhia Ecumenică</strong> - Închinare la moaştele
            Sfântului Grigorie Teologul, Sfântului Ioan Gura de Aur, Sfintei
            Solomoni, Sfintei Eufimia si Sfintei Teofana.
          </li>
        </StyledList>
      </ContentSection>

      <ContentSection title="Note Importante">
        <ImportantNote>
          Autorităţile vamale îşi rezervă dreptul de a refuza intrarea pe
          teritoriul statului a oricărei persoane care nu prezintă suficientă
          încredere chiar dacă aceasta a obţinut acordul pentru obţinerea vizei
          turistice. Agenţia de turism nu poate fi făcută răspunzătoare pentru
          astfel de situaţii ce nu pot fi depistate anterior plecării.
        </ImportantNote>

        <StyledList>
          <li>
            Minorii trebuie să îndeplinească următoarele condiţii pentru a fi
            lăsaţi să iasă din ţară: să călătorească cu cel puţin un adult
            însoţitor; să aibă asupra lor acordul ambilor părinţi (sau al
            părintelui care nu-i însoţeşte) legalizat la notariat; adultul
            care-i însoţeşte, în cazul în care acesta nu este unul dintre
            părinţi, trebuie să aibă cazier judiciar pe care să-l prezinte la
            frontieră)
          </li>
          <li>
            Programul acţiunii poate fi modificat de către conducătorul de grup,
            ca ordine de desfăşurare sau se pot înlocui unele obiective sau
            hoteluri în funcţie de anumite situaţii speciale
          </li>
          <li>
            Categoria hotelurilor şi a mijloacelor de transport este cea
            oficială pentru Grecia
          </li>
          <li>
            Distributia camerelor la hotel se face de catre insoțitorul de grup;
            problemele legate de amplasarea sau aspectul camerei se rezolva de
            catre pelerin direct la receptie, asistat de insotitorul de grup
          </li>
          <li>
            Pentru anumite facilitati din hotel sau din camera, hotelierul poate
            solicita taxe suplimentare (minibar/frigider, seif etc.); in
            momentul sosirii la hotel solicitati receptionerului sa va informeze
            cu exactitate asupra lor
          </li>
          <li>
            Prezentul document constituie anexa la contractul de prestari
            servicii
          </li>
          <li>
            Agentia nu se obliga sa gaseasca partaj persoanelor care calatoresc
            singure
          </li>
          <li>
            Biletele de avion sunt nerambursabile si nu putem recupera avansul
            în cazul in care nu doriți să mai finalizați rezervarea
          </li>
          <li>
            Compania aeriană îşi rezervă dreptul de a modifica orarul de zbor
          </li>
          <li>
            Compania aeriana nu stabileste locurile in avion, pentru imbarcare
            se aplica metoda „primul venit, primul servit"
          </li>
        </StyledList>
      </ContentSection>

      <ContentSection title="Pregătirea Duhovnicească pentru Pelerinaj">
        <Typography sx={{ ...textStyles.regular, mb: 2 }}>
          Vă sfătuim cu părintească grijă:
        </Typography>
        <StyledList>
          <li>
            Să vă spovediţi şi să luaţi binecuvântarea duhovnicului dvs. pentru
            a face acest Pelerinaj pe urmele Sfinților
          </li>
          <li>Să iertaţi pe cei cu care aţi avut conflicte şi supărări</li>
          <li>Să citiţi în această perioadă din viețile Sfinților, acatiste</li>
          <li>
            Să pregătiţi de acasă pomelnicele cu numele celor apropiaţi –
            pomelnice pe care doriţi să le lăsaţi în bisericile importante (să
            scrieţi întotdeauna numele de botez a celui pomenit - cu majuscule,
            fără diminutive)
          </li>
          <li>
            Să pregătiţi batiste noi sau mici obiecte pe care le veţi atinge de
            Icoane făcătoare de minuni și de raclele cu Sfintele Moaște
          </li>
        </StyledList>
      </ContentSection>

      <ContentSection title="Sfaturi Practice">
        <StyledList>
          <li>
            <strong>PREGĂTIREA GRUPULUI:</strong>
            <br />
            În vederea informării dumneavoastră, pentru buna organizare şi
            pentru cunoaşterea participanţilor, vă recomandăm călduros să
            participaţi la întâlnirile pregătitoare care se organizează în
            vederea pelerinajului în Turcia.
          </li>
          <li>
            <strong>BANII DE CHELTUIALĂ:</strong>
            <br />
            Este bine să aveți de-acasă euro, în bancnote mici (1-20 euro).
            Puteţi foloși şi cardurile și cărti internaţionale de credit.
          </li>
          <li>
            <strong>DEPLASĂRILE:</strong>
            <br />
            În general, vom folosi autocarul pentru a ajunge cât mai aproape de
            obiectivele propuse dar nu întotdeauna vom avea acces cu autocarul
            în apropierea locurilor şi a obiectivelor care ne interesează. De
            aceea, este bine să aveţi încălţăminte comodă.
          </li>
          <li>
            <strong>APARATE FOTO / VIDEO:</strong>
            <br />
            Fotografiile păstrează amintirea locurilor minunate pe care le-aţi
            văzut şi a oamenilor pe care i-aţi întâlnit. Este bine să aveţi la
            dumneavoastră un aparat foto sau o cameră video. Pelerinajul
            presupune şi relaţia specială care se stabileşte cu oamenii cu care
            călătoreşti: este bine să aveţi cărţi de vizită pe care să le puteţi
            oferi celor din grup.
          </li>
          <li>
            La programele de pelerinaj recomandăm să se înscrie persoane cu
            stare fizică bună, deoarece vizitele și deplasările pot să dureaze
            7-9 ore / zi (stat în picioare și mers pe jos)
          </li>
          <li>
            <strong>SIGURANŢĂ / SĂNĂTATE:</strong>
            <br />
            Asigurarea de călătorie este obligatorie, dar este bine să ne
            informaţi din timp dacă suferiţi de afecţiuni deosebite. Să nu
            uitaţi medicamentele recomandate de medicul dvs. Pentru siguranţa
            dumneavoastră, toate locurile importante vizitate de pelerini sunt
            protejate de poliţia turistică, gărzi private şi (sau) subunităţi
            militare.
          </li>
          <li>
            <strong>CUMPĂRĂTURILE:</strong>
            <br />
            Programul nostru de pelerinaj este foarte dens, aproape tot timpul
            este alocat vizitelor şi nu am prevăzut mult timp pentru magazine
            sau pieţe. Acest lucru îl vom realiza doar dacă timpul ne va permite
            (fără să prejudiciem programul de vizitare prevăzut). Veţi avea
            însă, posibilitatea să cumpăraţi suvenire care se comercializează în
            preajma obiectivelor vizitate.
          </li>
          <li>
            <strong>ÎMBRĂCĂMINTEA:</strong>
            <br />
            Vă recomandăm, pentru propriul dvs. confort, să vă gestionaţi corect
            conţinutul bagajelor, să nu transportaţi bagaje grele şi inutile. În
            pelerinaj nu etalăm vestimentaţie pretenţioasă sau bijuterii. Să
            aveţi în bagajul dvs. şi un costum de baie + prosop - nu excludem
            posibilitatea de a face baie în mare. Este bine să vă luaţi
            îmbrăcămintea obişnuită (casual) şi mai ales încălţămintea cea mai
            comodă. În zilele în care avem mai multe ore "în mişcare" – este
            foarte practic dacă aveţi un mic rucsac în care să aveţi sticluţa cu
            apă şi alte obiecte esenţiale.
          </li>
        </StyledList>
      </ContentSection>

      <ContentSection title="Nu uitați">
        <Typography sx={{ ...textStyles.regular, mb: 2 }}>
          Nu uitati să puneţi în bagaj:
        </Typography>
        <Typography sx={{ ...textStyles.regular, fontStyle: "italic", mb: 4 }}>
          Credinţa, nădejdea şi dragostea, bucuria, pacea, evlavia, prietenia,
          gânduri, cuvinte frumoase şi dorinţa fierbinte de a ajunge la Locurile
          Sfinte!
        </Typography>

        <Typography sx={{ ...textStyles.bold, textAlign: "center" }}>
          Ne bucurăm că aţi decis să faceţi acest pelerinaj împreună cu noi!
        </Typography>
      </ContentSection>
    </StyledContainer>
  );
};

export default PelerinajCappadocia;
