import React from "react";
import { Typography } from "@mui/material";
import {
  StyledContainer,
  PageTitle,
  ContentSection,
  StyledList,
  InfoBox,
  ImportantNote,
  textStyles,
} from "../../components/common/StyledComponents";

const PelerinajAthos = () => {
  return (
    <StyledContainer>
      <PageTitle>Pelerinaj în Sfântul Munte Athos</PageTitle>

      <ContentSection title="Informații Principale">
        <InfoBox>
          <Typography sx={textStyles.regular}>Perioada: 15-20 iunie</Typography>
          <Typography sx={textStyles.regular}>Tariful: 650 euro</Typography>
        </InfoBox>
      </ContentSection>

      <ContentSection title="Servicii Incluse">
        <StyledList>
          <li>Transport cu microbus/autocar spre Grecia</li>
          <li>1 noapte cazare intermediara</li>
          <li>Vapor spre și din Sfântul Munte</li>
          <li>2 nopți cazare și masă la mănăstiri în Sf. Munte</li>
          <li>Transport cu microbus în Sfântul Munte</li>
          <li>
            Preot însoțitor-ghid de turism specializat în turism religios
            vorbitor de română
          </li>
          <li>Asigurarea medicală</li>
        </StyledList>
        <ImportantNote>
          Asigurarea de sanatate se poate incheia DOAR pentru persoanele care
          pot dovedi prin CARTEA DE IDENTITATE ca au DOMICILIUL sau RESEDINTA in
          ROMANIA, vârsta până la 80 ani. Pentru persoanele care nu se
          incadreaza in cele mentionate mai sus, NU SE POATE INCHEIA ASIGURARE
          DE SANATATE.
        </ImportantNote>
      </ContentSection>

      <ContentSection title="Servicii Neincluse">
        <StyledList>
          <li>Diamonitirion/viză (10 euro preoții, 25 euro mirenii)</li>
        </StyledList>
      </ContentSection>

      <ContentSection title="Acte Necesare">
        <StyledList>
          <li>
            Carte de identitate sau pașaport valabil minim 6 luni de la data
            întoarcerii în țară.
          </li>
        </StyledList>
      </ContentSection>

      <ContentSection title="Detalii Importante">
        <Typography sx={{ ...textStyles.bold, color: "#800020", mb: 2 }}>
          NU EXISTĂ POSIBILITATEA DE CAMERĂ SINGLE
        </Typography>
        <InfoBox>
          <Typography sx={textStyles.regular}>
            Avans 200 euro/persoană. Plata integrală cu 30 zile înainte de
            derularea pelerinajului.
          </Typography>
          <Typography sx={textStyles.regular}>
            Așezarea în microbus se face în ordinea corfimării locului prin
            achitarea avansului.
          </Typography>
        </InfoBox>
      </ContentSection>

      <ContentSection title="Traseu și Program">
        <Typography sx={textStyles.regular}>
          Ouranopoli Grecia - Cazare Ouranopoli – Sfântul Munte Athos.
        </Typography>

        <Typography variant="h6" sx={{ ...textStyles.bold, mt: 3, mb: 2 }}>
          Se fac popasuri duhovnicești la:
        </Typography>

        <StyledList>
          <li>
            <strong>Mănăstirea Marea Lavră</strong> - Icoana "Cucuzelita". Se
            spune despre aceasta ca Sfantul Ioan Cucuzel a adormit la o
            priveghere iar Maica Domnului i-a spus din icoana: "Canta-mi, Ioane,
            si ma lauda, ca si eu nu te voi lasa!"
          </li>
          <li>
            <strong>Icoana "Econoama"</strong>. Sfantul Athanasie fiind
            ingrijorat din pricina banilor care nu ajungeau, Maica Domnului i-a
            grait: "Nu te intrista, Athanasie, de acum eu voi fi economul
            acestei manastiri", si de atunci pana in zilele noastre manastirea
            nu are econom.
          </li>
          <li>
            <strong>Icoana "Portarita"</strong>. Un turc a tras in icoana cu
            pistolul si al treilea glonte s-a intors si l-a lovit pe el. Minunea
            a facut din aceasta icoana pazitoarea manastirii.
          </li>
          <li>
            <strong>Schitul Românesc Prodromu</strong> - Cel mai de preț odor al
            Schitului Prodromu este icoana făcătoare de minuni a Maicii Domnului
            „Prodromiţa", a cărei prăznuire se face pe 12 iulie. Deşi icoana
            este atribuită iconarului român Iordache Nicolau din Iaşi, chipul
            Maicii Domnului şi al pruncului Iisus au fost zugrăvite în urma unei
            minuni. Îmbrăcată în argint, icoana Maicii Domnului este așezată în
            biserica mare a schitului dinaintea catapetesmei, în partea stângă.
            De-a lungul timpului, au fost consemnate numeroase minuni datorate
            icoanei Maicii Domnului „Prodromiţa".
          </li>
          <li>
            La Schitul Prodromu se mai află și alte icoane făcătoare de minuni.
            Icoana Maicii Domnului „Apărătoarea de foc" a scăpat de multe ori
            schitul de incendiu. În fiecare an se face agheasmă și procesiune cu
            această sfântă icoană. În vistieria schitului se mai află şi icoana
            Maicii Domnului „Înainte Vestitoarea", apoi icoana Maicii Domnului
            „Ctitoriţa". În vechiul Paraclis al schitului cu hramul „Sfântul
            Ioan Botezătorul" se găsesc icoana Sfintei Treimi și icoana
            Sfântului Ioan Botezătorul, ce a fost îmbrăcată în argint în anul
            1853 de către Grigorie Al. Ghica, domnitorul Moldovei.
          </li>
          <li>
            Dintre sfintele moaște care se află în biserica schitului amintim pe
            cele de la: Sfinții Ierarhi Ioan Gură de Aur, Grigorie Teologul și
            Modest, Patriarhul Ierusalimului, Sfântul Apostol Matei, Sfânta Mare
            Muceniță Varvara, Sfinții 40 de Mucenici din Sevastia, Sfântul
            Mucenic Trifon, Sfinții Doctori fără de arginți Cosma și Damian și
            Cuviosul Antipa de la Calapodești.
          </li>
          <li>
            <strong>Schitul Românesc Lacu</strong> - inchinare la icoana Maicii
            Domnului „Lacuschitiotisa". La biserica mare a schitului se află o
            părticică din Lemnul Sfintei Cruci și câte o părticică din moaștele
            Sfântului Haralambie și a Sfinților Mucenici de la schitul Ivirului:
            Eftimie, Ignatie și Acachie.
          </li>
          <li>
            De asemenea, la Chilia Sfântului Mare Mucenic Artemie se găsește o
            părticică din Lemnul Sfintei Cruci și părticele din moaștele mai
            multor sfinți: Sfântul Mare Mucenic Artemie, Sfântul Mare Mucenic
            Gheorghe, Sfântul Mare Mucenic Pantelimon, Cuviosul Siluan Atonitul,
            Cuviosul Sofronie Caliga (care a fost stareț al Mănăstirii Sfântul
            Pavel) ș.a.
          </li>
          <li>
            Multe mici părticele de sfinte moaște sunt și la celelalte chilii
            ale Schitului Lacu.
          </li>
          <li>
            <strong>Iviron</strong> – inchinare la icoana Maicii Domnului
            „Portarita" - cea care a venit singura pe mare.
          </li>
          <li>
            <strong>Stavronikita</strong> - Printre odoarele manastirii se
            pastreaza si Sfinte Moaste: mana Sfintei Ana, mana Sfantului
            Elefterie, particele de la Sfintii Trei Ierarhi si de la alti
            sfinti. In biserica manastirii se afla icoana Sfantului Ierarh
            Nicolae, care a fost gasita in mare. Odata, parintii manastirii au
            iesit la pescuit si, aruncand mreaja in mare, cand au tras-o la mal
            au vazut ca in loc de peste, in mreaja se afla icoana Sfantului
            Ierarh Nicolae, avand pe frunte o scoica. Cand au dezlipit scoica de
            pe locul unde fusese lipita, a inceput sa curga sange, dupa cum se
            poate vedea pana astazi, iar pe mal, pe locul unde au iesit din
            mare, a izvorat un izvor cu apa dulce.
          </li>
          <li>
            <strong>Pantokrator</strong> - Icoana facatoare de minuni a Maicii
            Domnului Gherontissa este cu siguranta cel mai de pret odor al
            Manastirii Pantocrator. Praznuirea acesteia sa se face la 2
            decembrie. Dupa traditie, ea a fost daruita de imparatul bizantin
            Alexie I Comnenul, fiind o copie dupa icoana in mozaic a Maicii
            Domnului "Grabnic Ajutatoarea" de la Manastirea Pantocrator din
            Constantinopol.
          </li>
          <li>
            <strong>Vatopedu</strong> – inchinare la icoanele facatoare de
            minuni ale Maicii Domnului, Braul Maicii Domnului si Capul Sfantului
            Ion Gura de Aur cu urechea stanga neputrezita.
          </li>
          <li>
            Icoanele facatoare de minuni ale Maicii Domnului care se pastreaza
            la Manastirea Vatoped, sapte la numar, sunt: "Vimatarissa", adica
            "Altarita", "Paramythia", adica "Mangaietoarea", "Pantanassa", adica
            "Vindecatoarea de cancer", "Elaiovrytissa", adica "Izvoratoarea de
            untdelemn", "Antiphonitria", adica "Impotriva glasuitoarea",
            "Esphagmeni", adica "Cea Injunghiata" si "Pyrovolitheisa", adica
            "Cea Impuscata", aceasta din urma aflata deasupra portii de intrare
            in manastire.
          </li>
          <li>
            Icoana Maicii Domnului "Ctitorita" a fost ascunsa de navalirea
            paganilor, de un calugar, intr-o groapa, sub Sfanta Masa, cu o
            lumanare aprinsa in fata ei, iar dupa 72 de ani a fost gasita icoana
            in cel loc, cu lumanarea inca arzand. Calugarul respectiv fusese
            luat prizonier de pagani si cand a ajuns la manastire, dupa 72 de
            ani, le-a spus calugarilor sa sape in acel loc si astfel au gasit
            icoana.
          </li>
          <li>
            Icoana Maicii Domnului "Junghiata" a fost lovita de un diacon,
            suparat fiind pe bucatar, fiindca nu a vrut sa-i mai dea mancare,
            cand a intarziat la masa. Diaconul a lovit icoana cu un cutit si
            imediat a curs sange din icoana. Diaconul si-a venit in fire si si-a
            dat seama de greseala facuta si s-a rugat Maicii Domnului sa-l ierte
            neclintit in fata icoanei, iar dupa cativa ani Maica Domnului i-a
            spus ca l-a iertat dar mana care a lovit icoana va da socoteala la
            judecata lui Dumnezeu. Iar dupa moartea lui mana respectiva i-a
            ramas uscata ca marturie.
          </li>
          <li>
            Icoana Macii Domnului "Izvabitoarea de barbari" arata inainte de
            aceasta minune ca o icoana obisnuita. Portile manastirii se tineau
            inchise in timpul noptii de navalirea paganilor, iar dimineata se
            deschideau. Un calugar s-a inchinat acestei icoane dimineata, iar
            Maica Domnului i-a vorbit din icoana si i-a spus sa nu deschida
            portile manastirii, fiindca sunt piratii la porti si vor prada
            manastirea si o vor incendia, atunci Mantuitorul isi ridica mana si
            ii acopera gura Maicii Domnului si ii spune sa-i lase in pace pe
            calugarii acestia, fiindca nu fac voia lui, atunci Maica Domnului ii
            apuca mana Mantuitorului si o lasa in jos si spune din nou acelasi
            lucru. Iar icoana ramane asa. Intr-adevar piratii erau la portile
            manastirii si astfel s-a izbavit manastirea si calugarii de
            distrugere.
          </li>
          <li>
            Icoana Maicii Domnului "Antifomitria" este una foarte veche. Astfel,
            in anul 382, fiica lui Teodosie cel Mare vine la ctitoria tatalui
            sau, sa vada cum decurg lucrarile de reparatie, si se inchina la
            icoana Maicii Domnului. Din icoana se auzi: "Ce cauti aici? De acum
            incolo sa nu mai calce picior de femeie pe aici." De atunci s-a
            interzis accesul femeilor in Muntele Athos.
          </li>
          <li>
            <strong>Biserica Protaton</strong> – inchinare la icoana Maicii
            Domnului „Axion Estin" – Vrednică Ești. Icoana "Axion Estin", legata
            de minunea ce o vom pomeni mai jos, se afla in Chilia Adormirii
            Maicii Domnului, asezata langa Schitul Sfantul Andrei. Pe langa
            lucrarea acesteia intr-un deosebit si duhovnicesc stil, icoana este
            minunata prin descoperirea rugaciunii "Cuvine-se cu adevarat.." In
            anul 980, un ucenic se ruga in fata icoanei cu rugaciunea: "Ceea ce
            esti mai cinstita decat heruvimii si mai marita fara de asemanare
            decat serafimii, care fara stricaciune pe Dumnezeu Cuvantul ai
            nascut, pe tine cea cu adevarat Nascatoare de Dumnezeu te marim." In
            acel moment apare Sfantul Arhanghel Gavriil, in chipul unui calugar,
            si ii spune sa se roage asa "Cuvine-se cu adevarat sa te fericim pe
            tine Nascatoare de Dumnezeu, cea pururea fericita si preanevinovata
            si maica Dumnezeului nostru" la care sa adauge rugaciunea pe care
            deja o stia "Ceea ce esti mai cinstita...", dar ucenicul, neretinand
            exact cantarea, ingerul a scris-o cu degetul in intregime pe o
            lespede de piatra. Aceasta piatra se pastreaza pana in zilele
            noastre.
          </li>
          <li>
            <strong>Mănăstirea Cutlumuș</strong>– inchinare la icoanele Maicii
            Domnului „Ocrotitoare Nebiruita"si „Milostiva". In biserica
            manastirii se afla icoana Mantuitorului si a Maicii Domnului, numita
            "Mijlocitoarea" si "Pazitoarea de rele", ambele facatoare de minuni.
            In paraclisul manastirii se afla icoana Maicii Domnului"Tare
            Aparatoare" (secolul XIII), aparatoare de foc si de frica.
          </li>
          <li>
            In sfinte racle se pastreaza Moaste de la mai multi sfinti: Capul
            Sfantului Haralambie, Piciorul Sfintei Ana, Capul Sfantului Alipie
            Stalpnicul, precum si Mana stanga a Sfantului Grigore Teologul
            (toate daruite de primul sau ctitor, imparatul bizantin Alexie
            Comnenul), Sfanta Paraschevi, Sfanta Anastasia (izbavitoarea de
            otrava), cat si multe alte particele din moastele altor 70 de
            sfinti. Cea mai valoroasa comoara a manastirii este insa un fragment
            din Sfanta Cruce a Domnului nostru Iisus Hristos.
          </li>
          <li>
            Icoana Maicii Domnului "Pazitoarea de rele" sau "Tare Aparatoare"
            (Phovera Prostasia) este singurul obiect ramas in urma unui incendiu
            ce a distrus complet un schit al manastirii din Insula Creta.
            Aceasta icoana a fost adusa in Sfantul Munte Athos, in Manastirea
            Cutlumus, unde a savarsit nenumarate minuni. In vremea unui incendiu
            petrecut in padurile manastirii, calugarii au luat icoana si au
            mers, cu rugaciune, spre padurea ce ardea. In scurt timp, cerul a
            dat ploaie si padurea nu a mai ars.
          </li>
          <li>
            Icoana Maicii Domnului "Pazitoarea de rele" se afla pe catapeteasma
            capelei manastirii. Icoana este dusa, in procesiune, in Biserica
            Protaton, o data pe an, in Martea Luminata. Iar icoana Axion Estin,
            pastrata in Biserica Protaton, ii intoarce vizita la o zi distanta.
          </li>
          <li>
            <strong>Schitul „Sfantul Andrei" (Serai)</strong> – inchinare la
            moaştele (fruntea) Sfântului Apostol Andrei. In biserica schitului
            se pastreaza o icoana facatoare de minuni a Maicii Domnului,
            renumita pentru tamaduirea mai multor muti si paralizati. Alaturi de
            aceasta, monahii pastreaza, cu mare evlavie, o parte din Moastele
            Sfantului Apostol Andrei (fruntea).
          </li>
        </StyledList>
      </ContentSection>

      <ContentSection title="Informații Administrative">
        <Typography sx={textStyles.regular}>
          Vă așteptăm să fim împreună pelerini spre mântuire! Grupul va fi
          însoțit de părintele Valeriu Mircea
        </Typography>
        <ImportantNote>
          *Programul este informativ, poate suferi modificări în funcție de
          condițiile meteo și orarul de vizitare al mănăstirilor pentru
          pelerini!
        </ImportantNote>
        <Typography sx={{ ...textStyles.regular, mt: 2 }}>
          Plata parțială sau integrală se poate efectua la sediul Agenției
          Pelerinul Creștin sau, după o rezervare anterioară, prin ordin de
          plată la orice sediu al Băncii Transilvania, fapt ce constituie
          consimțământul și acordul deplin al PELERINULUI asupra tuturor
          clauzelor contractuale, precum și asupra programului detaliat al
          pelerinajului.
        </Typography>
      </ContentSection>

      <ContentSection title="Note Importante">
        <ImportantNote>
          * Autorităţile din Sfântul Munte Athos îşi rezervă dreptul de a refuza
          intrarea pe teritoriul statului a oricărei persoane care nu prezintă
          suficientă încredere chiar dacă aceasta a obţinut acordul pentru
          obţinerea vizei turistice. Agenţia de turism nu poate fi făcută
          răspunzătoare pentru astfel de situaţii ce nu pot fi depistate
          anterior plecării. Turistul îşi asumă riscurile de a-i fi refuzată
          intrarea şi de aceea este obligat să informeze agenţia asupra oricărui
          incident petrecut vreodată în afara graniţelor României, precum şi
          asupra oricărei tangenţe pe care o are cu statele în care are loc
          desfăşurarea pelerinajului.
        </ImportantNote>

        <StyledList>
          <li>
            Minorii trebuie să îndeplinească următoarele condiţii pentru a fi
            lăsaţi să iasă din ţară: să călătorească cu cel puţin un adult
            însoţitor; să aibă asupra lor acordul ambilor părinţi (sau al
            părintelui care nu-i însoţeşte) legalizat la notariat; adultul
            care-i însoţeşte, în cazul în care acesta nu este unul dintre
            părinţi, trebuie să aibă cazier judiciar pe care să-l prezinte la
            frontieră).
          </li>
          <li>
            Programul acţiunii poate fi modificat de către conducătorul de grup,
            ca ordine de desfăşurare sau se pot înlocui unele obiective sau
            hoteluri în funcţie de anumite situaţii speciale.
          </li>
          <li>
            Categoria hotelurilor şi a mijloacelor de transport este cea
            oficială pentru țările pe care le vizităm.
          </li>
          <li>
            Distributia camerelor la hotel se face de catre insoțitorul de grup;
            problemele legate de amplasarea sau aspectul camerei se rezolva de
            catre pelerin direct la receptie, asistat de insotitorul de grup.
          </li>
          <li>
            Pentru anumite facilitati din hotel sau din camera, hotelierul poate
            solicita taxe suplimentare (minibar/frigider, seif etc.); in
            momentul sosirii la hotel solicitati receptionerului sa va informeze
            cu exactitate asupra lor.
          </li>
          <li>
            Prezentul document constituie anexa la contractul de prestari
            servicii
          </li>
          <li>
            Agentia nu se obliga sa gaseasca partaj persoanelor care calatoresc
            singure.
          </li>
        </StyledList>
      </ContentSection>

      <ContentSection title="Sfaturi Practice">
        <Typography variant="h6" sx={{ ...textStyles.bold, mb: 2 }}>
          SFATURI ŞI INFORMAŢII PRACTICE pentru pelerinajul în Sfântul Munte
          Athos
        </Typography>

        <Typography variant="subtitle1" sx={{ ...textStyles.bold, mb: 1 }}>
          PREGĂTIREA GRUPULUI
        </Typography>
        <Typography sx={{ ...textStyles.regular, mb: 2 }}>
          În vederea informării dumneavoastră, pentru buna organizare şi pentru
          cunoaşterea participanţilor, vă recomandăm călduros să participaţi la
          întâlnirile pregătitoare care se organizează în vederea pelerinajului
          în Grecia.
        </Typography>

        <Typography variant="subtitle1" sx={{ ...textStyles.bold, mb: 1 }}>
          DOCUMENTELE
        </Typography>
        <Typography sx={{ ...textStyles.regular, mb: 2 }}>
          Înainte de plecarea în călătorie verificaţi încă o dată dacă aţi luat
          de acasă cartea de indentitate sau paşaportul dumneavoastră.
        </Typography>

        <ImportantNote sx={{ mb: 2 }}>
          Autoritătile elene sau ale republicii monahale Athos își rezervă
          dreptul de a refuza intrarea pe teritoriul statului a oricărei
          persoane care nu prezintă suficientă încredere sau cu antecedente pe
          teritoriul statului Grecia.
        </ImportantNote>

        <Typography variant="subtitle1" sx={{ ...textStyles.bold, mb: 1 }}>
          BANII DE CHELTUIALĂ
        </Typography>
        <Typography sx={{ ...textStyles.regular, mb: 2 }}>
          Este bine să aveți de-acasă euro, în bancnote mici (1-20 euro). Puteţi
          foloși şi cărti internaţionale de credit/carduri bancare.
        </Typography>

        <Typography variant="subtitle1" sx={{ ...textStyles.bold, mb: 1 }}>
          DEPLASĂRILE
        </Typography>
        <Typography sx={{ ...textStyles.regular, mb: 2 }}>
          În general, vom folosi microbusul pentru a ajunge cât mai aproape de
          obiectivele propuse dar nu întotdeauna vom avea acces cu microbusul în
          apropierea locurilor şi a obiectivelor care ne interesează, vom
          parcurge mici trasee și pe jos. De aceea, este bine să aveţi
          încălţăminte comodă.
        </Typography>

        <Typography variant="subtitle1" sx={{ ...textStyles.bold, mb: 1 }}>
          APARATE FOTO / VIDEO
        </Typography>
        <Typography sx={{ ...textStyles.regular, mb: 2 }}>
          Fotografiile păstrează amintirea locurilor minunate pe care le-aţi
          văzut şi a oamenilor pe care i-aţi întâlnit. Este bine să aveţi la
          dumneavoastră un aparat foto sau o cameră video. Pelerinajul presupune
          şi relaţia specială care se stabileşte cu oamenii cu care călătoreşti:
          este bine să aveţi cărţi de vizită pe care să le puteţi oferi celor
          din grup.
        </Typography>

        <ImportantNote sx={{ mb: 2 }}>
          La programele de pelerinaj recomandăm să se înscrie persoane cu stare
          fizică bună, deoarece vizitele și deplasările pot să dureaze 7-12 ore
          / zi (stat în picioare și mers pe jos)
        </ImportantNote>

        <Typography variant="subtitle1" sx={{ ...textStyles.bold, mb: 1 }}>
          SIGURANŢĂ / SĂNĂTATE
        </Typography>
        <Typography sx={{ ...textStyles.regular, mb: 2 }}>
          Asigurarea de călătorie este obligatorie, dar este bine să ne
          informaţi din timp dacă suferiţi de afecţiuni deosebite. Să nu uitaţi
          medicamentele recomandate de medicul dvs. Pentru siguranţa
          dumneavoastră, toate locurile importante vizitate de pelerini sunt
          protejate de poliţia turistică, gărzi private şi (sau) subunităţi
          militare.
        </Typography>

        <Typography variant="subtitle1" sx={{ ...textStyles.bold, mb: 1 }}>
          CUMPĂRĂTURILE
        </Typography>
        <Typography sx={{ ...textStyles.regular, mb: 2 }}>
          Programul nostru de pelerinaj este foarte dens, aproape tot timpul
          este alocat vizitelor şi nu am prevăzut mult timp pentru magazine sau
          pieţe. Acest lucru îl vom realiza doar dacă timpul ne va permite (fără
          să prejudiciem programul de vizitare prevăzut). Veţi avea însă,
          posibilitatea să cumpăraţi suvenire care se comercializează în preajma
          obiectivelor vizitate.
        </Typography>

        <Typography variant="subtitle1" sx={{ ...textStyles.bold, mb: 1 }}>
          ÎMBRĂCĂMINTEA
        </Typography>
        <Typography sx={{ ...textStyles.regular, mb: 2 }}>
          Vă recomandăm, pentru propriul dvs. confort, să vă gestionaţi corect
          conţinutul bagajelor, să nu transportaţi bagaje grele şi inutile. În
          pelerinaj nu etalăm vestimentaţia. *Să aveţi în bagajul dvs. şi un
          costum de baie + prosop - nu excludem posibilitatea de a face baie în
          mare. Este bine să vă luaţi îmbrăcămintea obişnuită (casual) şi mai
          ales încălţămintea cea mai comodă. În zilele în care avem mai multe
          ore "în mişcare" – este foarte practic dacă aveţi un mic rucsac în
          care să aveţi sticluţa cu apă şi alte obiecte esenţiale. În Grecia apa
          de la robinet este potabilă.
        </Typography>

        <Typography variant="h6" sx={{ ...textStyles.bold, mb: 1 }}>
          Nu uitati să puneţi în bagaj:
        </Typography>

        <Typography sx={{ ...textStyles.regular, mb: 2 }}>
          Credinţa, nădejdea şi dragostea, bucuria, pacea, evlavia, prietenia,
          gânduri, cuvinte frumoase şi dorinţa fierbinte de a ajunge la Locurile
          Sfinte!
        </Typography>

        <Typography sx={{ ...textStyles.bold, mt: 4, textAlign: "center" }}>
          Ne bucurăm că aţi decis să faceţi acest pelerinaj împreună cu noi!
        </Typography>
      </ContentSection>
    </StyledContainer>
  );
};
export default PelerinajAthos;
