import React from "react";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import { styled } from "@mui/material/styles";
import { CircularProgress } from "@mui/material";

const baseButtonStyles = {
  display: "flex",
  height: "32px",
  justifyContent: "center",
  alignItems: "center",
  borderRadius: "30px",
  fontFamily: "Inter",
  fontSize: "14px",
  cursor: "pointer",
  fontWeight: 500,
  lineHeight: "18px",
  textTransform: "capitalize",
  "& .MuiButtonBase-root.MuiButton-root": {
    height: "32px!important",
    padding: "0px 10px!important",
  },

  "&[disabled]": {
    "& .MuiButton-startIcon": {
      "& .MuiBox-root": {
        background: "#A9A9A9 !important",
      },
    },
  },

  "& .MuiButton-startIcon": {
    marginRight: "6px!important",
  },
};
// OutlinedButton: Button with optional text and icon
const OutlinedButton = styled(Button)(({ theme, hasText }) => ({
  ...baseButtonStyles,
  display: "flex",
  height: "32px",
  padding: hasText ? "0px 10px" : "0px",
  justifyContent: "center",
  alignItems: "center",

  borderRadius: "30px",
  border: "1px solid",
  borderColor: "#D9D9E0",
  backgroundColor: theme.palette.background.default || "#FFF",
  color: theme.palette.text.primary || "#1C2024",
  fontFamily: "Inter",
  fontSize: "14px",
  fontWeight: 500,
  lineHeight: "18px",
  textTransform: "capitalize",
  width: hasText ? "fit-content" : "32px",
  minWidth: hasText ? "auto" : "32px",
  "&:hover": {
    backgroundColor: theme.palette.action.hover || "#f0f0f0",
  },
  "&.MuiButtonBase-root.MuiButton-root": {
    // height: "32px!important",
    //  padding: "0px 10px!important",
  },
}));

// OutlinedIconButton: Icon-only button with centered icon
const OutlinedIconButton = styled(IconButton)(({ theme }) => ({
  borderRadius: "30px",
  justifyContent: "center",
  alignItems: "center",
  border: "1px solid",
  borderColor: theme.palette.divider || "#D9D9E0",
  backgroundColor: theme.palette.background.default || "#FFF",
  color: theme.palette.text.primary || "#1C2024",
  "&:hover": {
    backgroundColor: theme.palette.action.hover || "#f0f0f0",
  },
  "&.MuiButtonBase-root.MuiIconButton-root": {
    height: "32px!important",
    width: "32px!important",
    padding: "0px!important",
  },
}));
// Contained Button with primary color styling
const ContainedButton = styled(Button)(({ hasText }) => ({
  ...baseButtonStyles,
  padding: "0px 10px",
  backgroundColor: "#800020",
  borderColor: "#3E63DD",
  border: "1px solid",
  color: "#FFF",
  cursor: "pointer !important",
  "&:hover": {
    backgroundColor: "#3355C4",
    color: "white",
  },
  "&[disabled]": {
    backgroundColor: "#A9A9A9", // Greyed out for disabled state
    border: "1px solid #A9A9A9", // Match the border to the background
    color: "#FFF", // Optional, change if needed
    cursor: "not-allowed", // Change cursor to indicate non-interactive
  },
}));

// Delete Button with delete color styling
const DeleteButton = styled(Button)(({ hasText }) => ({
  ...baseButtonStyles,
  padding: "0px 10px",
  backgroundColor: "#CE2C31",
  border: "1px solid",
  color: "#FFF",
  "&:hover": {
    backgroundColor: "#B32427",
  },
  "&[disabled]": {
    backgroundColor: "#A9A9A9", // Greyed out for disabled state
    border: "1px solid #A9A9A9", // Match the border to the background
    color: "#FFF", // Optional, change if needed
    cursor: "not-allowed", // Change cursor to indicate non-interactive
  },
}));
const TextButton = styled(Button)(({ hasText }) => ({
  ...baseButtonStyles,
  padding: "0px 10px",
  backgroundColor: "white",
  border: "none",
  color: "#3A5BC7",
  "&.MuiButtonBase-root.MuiIconButton-root": {
    height: "32px!important",
    width: "32px!important",
    padding: "0px!important",
  },
}));

export function CustomOutlinedButton({ icon, text, isLoading, ...props }) {
  const hasText = Boolean(text);

  return (
    <OutlinedButton startIcon={icon ? icon : null} hasText={hasText} {...props}>
      {isLoading ? (
        <CircularProgress
          sx={{
            color: "inherit",
            width: "20px !important",
            height: "20px !important",
          }}
        />
      ) : (
        text
      )}
    </OutlinedButton>
  );
}

export function CustomIconButton({ icon, onClick, ...props }) {
  return (
    <OutlinedIconButton {...props} onClick={onClick}>
      {icon}
    </OutlinedIconButton>
  );
}

export function CustomContainedButton({ icon, text, isLoading, ...props }) {
  const hasText = Boolean(text);
  return (
    <ContainedButton
      startIcon={icon ? icon : null}
      hasText={hasText}
      {...props}
    >
      {isLoading ? (
        <CircularProgress
          sx={{
            color: "inherit",
            width: "20px !important",
            height: "20px !important",
          }}
        />
      ) : (
        text
      )}
    </ContainedButton>
  );
}

export function CustomDeleteButton({ icon, text, isLoading, ...props }) {
  const hasText = Boolean(text);
  return (
    <DeleteButton startIcon={icon ? icon : null} hasText={hasText} {...props}>
      {isLoading ? (
        <CircularProgress
          sx={{
            color: "inherit",
            width: "20px !important",
            height: "20px !important",
          }}
        />
      ) : (
        text
      )}
    </DeleteButton>
  );
}
export function CustomTextButton({ icon, text, ...props }) {
  const hasText = Boolean(text);
  return (
    <TextButton
      variant="text"
      startIcon={icon ? icon : null}
      hasText={hasText}
      {...props}
    >
      {text}
    </TextButton>
  );
}
