import React from "react";
import { Card, CardContent, Typography, Container, Box } from "@mui/material";

const PelerinajItalia = () => {
  return (
    <Container className="py-8" sx={{ mt: 14 }}>
      <Typography variant="h3" className="mb-6 text-blue-900 font-bold">
        Pelerinaj în Italia
      </Typography>

      <Card className="mb-6">
        <CardContent>
          <Typography variant="h5" className="mb-4 text-blue-800 font-bold">
            Informații Principale
          </Typography>
          <Box className="space-y-3">
            <Typography>
              <span className="font-semibold">Durată:</span> 10 nopți
            </Typography>
            <Typography>
              <span className="font-semibold">Preț:</span> în curs de
              actualizare
            </Typography>
          </Box>
        </CardContent>
      </Card>

      <Card className="mb-6">
        <CardContent>
          <Typography variant="h5" className="mb-4 text-blue-800 font-bold">
            Servicii Incluse
          </Typography>
          <Box component="ul" className="list-disc list-inside space-y-2">
            <li>Transport cu autocar clasificat</li>
            <li>
              10 nopți cazare cu mic dejun la hotel 3-4* clasificare locală
            </li>
            <li>
              Preot însoțitor-ghid de turism specializat în turism religios
              vorbitor de română
            </li>
            <li>Ghidul pelerinului, eșarfă, ecuson</li>
            <li>Asigurare medicală</li>
          </Box>
        </CardContent>
      </Card>

      <Card className="mb-6">
        <CardContent>
          <Typography variant="h5" className="mb-4 text-blue-800 font-bold">
            Nu sunt incluse
          </Typography>
          <Box component="ul" className="list-disc list-inside space-y-2">
            <li>Intrările la obiectivele turistice și religioase</li>
            <li>Taxele de stațiune și ghizi locali</li>
            <li>
              Taxele de oraș (în Italia se plătesc la cazare și variază
              /persoană, în funcție de hotel)
            </li>
            <li>Bilet pentru vaporetto Veneția valabil 24 de ore</li>
            <li>Transportul public în orașe</li>
            <li>Mesele neincluse în program</li>
            <li>Cheltuielile personale</li>
          </Box>
        </CardContent>
      </Card>

      <Card>
        <CardContent>
          <Typography variant="h5" className="mb-4 text-blue-800 font-bold">
            Acte Necesare
          </Typography>
          <Box component="ul" className="list-disc list-inside space-y-2">
            <li>
              Carte de identitate sau pașaport valabil minim 6 luni de la data
              întoarcerii în țară
            </li>
          </Box>
        </CardContent>
      </Card>
    </Container>
  );
};

export default PelerinajItalia;
