import React from "react";
import { Typography, Box, Container } from "@mui/material";

const CuvantulIerarhului = () => {
  return (
    <Box
      sx={{
        width: "100%",
        py: 15,
        position: "relative",
        "&::before": {
          content: '""',
          position: "absolute",
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          background: `
            linear-gradient(
              to right,
              rgba(255, 247, 230, 0.8) 0%,
              rgba(255, 245, 220, 0.8) 35%,
              rgba(255, 245, 220, 0.8) 65%,
              rgba(255, 247, 230, 0.8) 100%
            ),
            repeating-linear-gradient(
              0deg,
              transparent,
              transparent 50px,
              rgba(177, 151, 119, 0.05) 50px,
              rgba(177, 151, 119, 0.05) 51px
            ),
            repeating-linear-gradient(
              90deg,
              transparent,
              transparent 50px,
              rgba(177, 151, 119, 0.05) 50px,
              rgba(177, 151, 119, 0.05) 51px
            )
          `,
          boxShadow: `
            inset 0 0 30px rgba(177, 151, 119, 0.2),
            inset 0 0 60px rgba(177, 151, 119, 0.1),
            0 0 10px rgba(177, 151, 119, 0.1)
          `,
          zIndex: -1,
        },
      }}
    >
      <Container maxWidth="lg">
        <Box
          sx={{
            position: "relative",
            "&::before": {
              content: '""',
              position: "absolute",
              top: 0,
              left: -20,
              right: -20,
              bottom: 0,
              background: "rgba(255, 250, 240, 0.5)",
              filter: "blur(10px)",
              zIndex: -1,
            },
          }}
        >
          <Typography
            variant="h4"
            component="h2"
            sx={{
              fontWeight: 700,
              textAlign: "center",
              fontFamily: "Cinzel",
              color: "#BE9257",
              mb: { xs: 0, md: 6 },
              textShadow: "1px 1px 2px rgba(190, 146, 87, 0.2)",
              fontSize: { xs: "1.75rem", md: "2.25rem" },
              letterSpacing: "0.02em",
            }}
          >
            Cuvântul Preasfințitului Părinte IUSTIN
          </Typography>

          <Box
            sx={{
              display: "grid",
              gridTemplateColumns: { xs: "1fr", md: "1fr 3fr" },
              gap: 4,

              p: 3,
              borderRadius: 2,
              background: "rgba(255, 250, 240, 0.7)",
            }}
          >
            {/* Image Column */}
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "flex-start",
                pt: 2,
              }}
            >
              <img
                src="/images/Iustin.jpeg"
                alt="Preasfințitul Părinte IUSTIN"
                style={{
                  maxWidth: "100%",
                  height: "auto",
                  maxHeight: "400px",
                  objectFit: "contain",
                }}
              />
            </Box>

            {/* Text Column */}
            <Box>
              <Typography
                variant="body1"
                sx={{
                  whiteSpace: "pre-line",
                  textAlign: "justify",
                  lineHeight: 1.8,
                  fontFamily: "Cormorant Garamond",
                  color: "#800020",
                  fontSize: { xs: "16px", md: "18px" },
                  fontWeight: 500,
                  letterSpacing: "0.01em",
                  mb: 3,
                }}
              >
                {" "}
                "Iubiți fii duhovnicești, <br /> Pelerinajul religios este o
                constantă a umanității. El are multiple semnificații când este
                trăit intens și înțeles corect. Pelerinii sunt oameni
                credincioși care doresc să viziteze și să cerceteze. Locuri care
                vorbesc și atestă istoric credinței lor. Locuri sfinte biblice
                morminte de martiri, moaște de sfinți, icoane făcătoare de
                minuni sau locuri unde au trăit sau trăiesc mari duhovnici.
                Pelerinul vrea să vadă și să atingă locul sfânt să simtă cu
                inima și sufletul ce au simțit cei ce au trăit credința in
                Dumnezeu în întreaga lor viață pământească.
              </Typography>
              <Typography
                variant="body1"
                sx={{
                  whiteSpace: "pre-line",
                  textAlign: "justify",
                  lineHeight: 1.8,
                  fontFamily: "Cormorant Garamond",
                  color: "#800020",
                  fontSize: { xs: "16px", md: "18px" },
                  fontWeight: 500,
                  letterSpacing: "0.01em",
                }}
              >
                De aceea pelerinajul intensifică rugăciunea și viața spirituală
                pe tot parcursul lui. Pelerinajul este un act de mulțumire adusă
                lui Dumnezeu pentru binefacerile primite, sau de asceza și
                ofranda prin osteneala și râvnă sfânta. Pelerinajul poate
                cuprinde un act de pocăință pentru păcate fiind completat de
                mărturisirea păcatelor și dezlegarea lor de către duhovnic,
                pentru mântuirea sufletească. Pelerinajul poate avea în sine
                dorința de a fi ajutat de Dumnezeu în situații pe care oamenii
                nu le pot rezolva: boală fizică sau sufletească , încercări și
                necazuri.
              </Typography>
            </Box>
          </Box>
          <Box
            sx={{
              display: "grid",
              gridTemplateColumns: { xs: "1fr", md: "1fr" },
              gap: 4,
              mb: 6,
              p: 3,
              borderRadius: 2,
              background: "rgba(255, 250, 240, 0.7)",
            }}
          >
            <Box sx={{ width: "100%", gap: 4 }}>
              <Typography
                variant="body1"
                sx={{
                  whiteSpace: "pre-line",
                  textAlign: "justify",
                  lineHeight: 1.8,
                  fontFamily: "Cormorant Garamond",
                  color: "#800020",
                  fontSize: { xs: "16px", md: "18px" },
                  fontWeight: 500,
                  letterSpacing: "0.01em",
                  mb: 3,
                }}
              >
                Semnificațiile spirituale ale pelerinajului:
              </Typography>

              <Typography
                variant="body1"
                sx={{
                  whiteSpace: "pre-line",
                  textAlign: "justify",
                  lineHeight: 1.8,
                  fontFamily: "Cormorant Garamond",
                  color: "#800020",
                  fontSize: { xs: "16px", md: "18px" },
                  fontWeight: 500,
                  letterSpacing: "0.01em",
                  mb: 4,
                }}
              >
                În pelerinaj omul și Dumnezeu se caută reciproc. În acest sens
                Avraam a devenit o icoana spirituală pelerinilor, părăsește
                patria sa, zona capadociei și pleacă departe spre țara pe care
                Dumnezeu i-o promite (Facere 12,15). Pelerinajul lui Avram este
                răspunsul lui la chemarea lui Dumnezeu. Astfel putem înțelege că
                Dumnezeu apelează pe omul pe care îl caută dacă acesta mai aude
                glasul său. Răspunsul omului: îl are Sf. Ap. Pavel (Evrei
                11,8-10). Pelerinajul este o căutare în lumea aceasta a ceea ce
                nu este din lumea pământească ci din lumea cerească unde sunt
                Dumnezeu sfinții și oameni iubiți de El. Hristos marele pelerin
                a venit să ne caute în pogorarea Lui printre oameni! A venit să
                caute și să mântuiască pe cel pierdut. El este Pastorul care
                caută, oaia cea pierdută, cel ce stă la ușă și bate așteptând ca
                cel pe care vrea să îl întâlnească, să audă glasul Său .
              </Typography>
              <Typography
                variant="body1"
                sx={{
                  whiteSpace: "pre-line",
                  textAlign: "justify",
                  lineHeight: 1.8,
                  fontFamily: "Cormorant Garamond",
                  color: "#800020",
                  fontSize: { xs: "16px", md: "18px" },
                  fontWeight: 500,
                  letterSpacing: "0.01em",
                  mb: 4,
                }}
              >
                Pelerinajul are un sens profetic, apocaliptic, eshatologic,
                popoarele care se adunau și cântă Slava Mielului în întâmpinarea
                Împărăției celei fără de sfârșit. Diversitatea de limbi,
                credința, culturi se întâlnesc în cadrul unui pelerinaj. Oamenii
                își împărtășesc experiențe de viață, bucurii și necazuri și de
                întorc mai bogați la casele lor. Pelerinaj este o sete de
                cunoaștere, de comuniune și împreună rugăciune. Este foame și
                sete după Dumnezeu. Iată de ce, din cele mai vechi timpuri
                oamenii au simțit nevoia să plece în pelerinaj la locurile lor
                sfinte. Toate aceste le caută creștinii într-un pelerinaj,
                dorind să le vadă, să le atingă să-și întărească credința în
                adevărul Evangheliei si sa se sfințească, pregătindu-se pentru
                viața veșnică cea fără sfârșit cu Hristos în Împărăția
                Preasfintei Treimi. Alături de aceste locuri sfinte legate de
                Mântuitorul sunt cele legate de Maica Domnului cum ar fi
                Mormântul din Ghetimani si cele legate de toți sfinții de la
                începuturile creștinismului și până astăzi. Unde au trăit, s-au
                rugat, au sfințit locurile și ni le-au lăsat nouă locuri sfinte
                și sfințitoare .
              </Typography>
              <Typography
                variant="body1"
                sx={{
                  whiteSpace: "pre-line",
                  textAlign: "justify",
                  lineHeight: 1.8,
                  fontFamily: "Cormorant Garamond",
                  color: "#800020",
                  fontSize: { xs: "16px", md: "18px" },
                  fontWeight: 500,
                  letterSpacing: "0.01em",
                  mb: 4,
                }}
              >
                Iată de ce pelerinajul este deodată comemorare și întâlnire în
                comuniune de credință și de rugăciune . Este înnoire și rezidire
                a omului întreg în toată ființa lui a cărui suflet nu se poate
                odihni decât în locuri sfinte unde Dumnezeu și-a arătat prezența
                Lui prin lucrarea Duhului Sfânt în viața sfinților. Avand
                această credință, aceste gânduri și convingeri creștinul aflat
                în pelerinajul pământesc se simte pe drumul către Împărăția lui
                Demnezeu , cunoscând bine ce ne-a spus Sfântul Apostol Pavel că:
                Nu avem aici cetate stătătoare ci o căutăm pe ceea ce va să fie
                - Epistola către Evrei (13, 14). Dumnezeu să binecuvinteze pe
                toți pelerinii creștini și pe toți cei din Eparhia noastră, care
                împreună cu Agenția Episcopiei noastre de Pelerinaje “Pelerinul
                Creștin” se ostenesc, se hrănesc și se zidesc din aceste
                călătorii misionare în care păstorii și păstoriții se întâlnesc
                cu Hristos , Maică Domnului și cu Sfinții lui Dumnezeu spre a le
                cunoaște lupta, viața și nevoința și a le urma credința. Slavă
                lui Dumnezeu pentru toate ! Amin!
              </Typography>
            </Box>
          </Box>

          <Box
            sx={{
              width: "100%",
              height: "1px",
              background:
                "linear-gradient(90deg, transparent, #BE9257 50%, transparent)",
              my: 4,
            }}
          />

          <Typography
            variant="body1"
            sx={{
              whiteSpace: "pre-line",
              textAlign: "center",
              lineHeight: 1.7,
              fontFamily: "EB Garamond",
              fontStyle: "italic",
              color: "#800020",
              fontSize: "16px",
              fontWeight: 500,
              "& strong": {
                fontFamily: "Playfair Display",
                fontWeight: 600,
                fontStyle: "normal",
                letterSpacing: "0.05em",
                display: "block",
                marginTop: "0.5em",
              },
            }}
          >
            {`Al vostru, al tuturor, de tot binele voitor și împreună cu voi rugător pentru darul vieții veșnice.

† IUSTIN
Episcopul Maramureșului și Sătmarului`}
          </Typography>
        </Box>
      </Container>
    </Box>
  );
};

export default CuvantulIerarhului;
