import React from "react";
import { Typography } from "@mui/material";
import {
  StyledContainer,
  PageTitle,
  ContentSection,
  StyledList,
  InfoBox,
  ImportantNote,
  textStyles,
} from "../../components/common/StyledComponents";

const PelerinajGrecia = () => {
  return (
    <StyledContainer>
      <PageTitle>Pelerinaj in Grecia – Pe urmele Sfinților</PageTitle>

      <ContentSection title="Informații Principale">
        <InfoBox>
          <Typography sx={textStyles.regular}>
            Tariful: în curs de actualizare/ pers./ loc in camera dublă
          </Typography>
          <Typography sx={textStyles.regular}>
            Supliment cameră single: 300 EURO
          </Typography>
        </InfoBox>
      </ContentSection>

      <ContentSection title="Servicii Incluse">
        <StyledList>
          <li>
            Bilet avion: dus-întors, bagaj de mână 10 kg (1 bucată nu troller)
          </li>
          <li>Bagaj de cală 20 kg</li>
          <li>
            Transport cu autocarul conform programului pe teritoriul Greciei
          </li>
          <li>7 nopti cazare la hotel 3-4* clasificare locală</li>
          <li>Masă (bufet) in regim demipensiune (mic dejun si cină)</li>
          <li>Feriboat-uri (persoane + autocar)</li>
          <li>Intrarile la toate obiectivele din program înafară de Meteora</li>
          <li>
            Preot însoțitor-ghid de turism specializat în turism religios
            vorbitor de română
          </li>
          <li>Ghidul pelerinului, eșarfă, ecuson</li>
          <li>Asigurarea medicală</li>
        </StyledList>
        <ImportantNote>
          Asigurarea de sanatate se poate incheia DOAR pentru persoanele care
          pot dovedi prin CARTEA DE IDENTITATE ca au DOMICILIUL sau RESEDINTA in
          ROMANIA, vârsta până la 80 ani. Pentru persoanele care nu se
          incadreaza in cele mentionate mai sus, NU SE POATE INCHEIA ASIGURARE
          DE SANATATE.
        </ImportantNote>
      </ContentSection>

      <ContentSection title="Servicii Neincluse">
        <StyledList>
          <li>
            Transport aeroport dus-întors Baia Mare Budapesta – aproximativ 50
            euro
          </li>
          <li>Intrările la Meteora (2 mănăstiri) - aproximativ 10 euro</li>
          <li>Intrarea la Acropole – 20 euro/persoană</li>
          <li>Taxele de stațiune aproximativ - 30 euro/persoană</li>
        </StyledList>
      </ContentSection>

      <ContentSection title="Acte Necesare">
        <StyledList>
          <li>
            Carte de identitate sau paşaport valabil minim 6 luni de la data
            întoarcerii în ţară
          </li>
        </StyledList>
      </ContentSection>

      <ContentSection title="Detalii Plată și Înscriere">
        <InfoBox>
          <Typography sx={textStyles.regular}>
            Supliment cameră single: 300 EURO
          </Typography>
          <Typography sx={textStyles.regular}>
            Avans 300-500 euro/persoană. Plata integrală cu 30 zile înainte de
            derularea pelerinajului.
          </Typography>
          <Typography sx={textStyles.regular}>
            Așezarea în autocar se face în ordinea înscrierilor.
          </Typography>
          <Typography sx={textStyles.regular}>
            Inscrierile se pot face la sediul agenției din bulevardul Traian nr.
            1B, Luni - Vineri: 9:00-15:00
          </Typography>
        </InfoBox>
      </ContentSection>

      <ContentSection title="Obiective Turistice">
        <Typography sx={textStyles.regular}>
          Ajunși pe tărâm elen, începem să descoperim capitala Greciei, cultura,
          civilizația și spiritualiatea elenă.
        </Typography>
        <StyledList>
          <li>Atena</li>
          <li>Eghina</li>
          <li>Sfântul Efrem cel Nou de la Nea Makri</li>
          <li>Sfântul Ierarh Nectarie, făcătorul de minuni</li>
          <li>
            Sf. Patapie Catedrala Mitropolitană, locul martiriului Sfântul
            Apostol Andrei, încreștinătorul României
          </li>
          <li>Patras</li>
          <li>Corfu</li>
          <li>Kerkyra</li>
          <li>Sf. Spiridon</li>
          <li>
            Sf. Teodora Împărăteasa cea care a reașezat icoanele in calendarul
            ortodox
          </li>
          <li>Kalambaka</li>
          <li>
            Meteora – Mijlocul cerului. Popasuri duhovnicești la 1-2 mănăstiri
            de la Complexul Monahal de la Meteora, în funcție de timp,
            Patrimoniu UNESCO, dupa care ne oprim la atelierul iconografic
            pentru vedea cum se realizeaza icoanele
          </li>
          <li>Salonic</li>
          <li>Evia</li>
          <li>Biserica Sfântul Dimitrie</li>
          <li>Catedrala Sfântul Grigorie Palama</li>
          <li>Turnul Alb Salonic</li>
          <li>Evia</li>
          <li>Sfinților ne duce la Prokopi</li>
          <li>Sfântul Ioan Rusul</li>
          <li>
            Acropolei. Acropola din Atena - „oraș ridicat", colina sacră
            fortificată din centrul orașului, este cea mai cunoscută acropolă
            din lume, scurtă oprire la Aeropag
          </li>
        </StyledList>
      </ContentSection>

      <ContentSection title="Note Importante">
        <ImportantNote>
          Autorităţile vamale îşi rezervă dreptul de a refuza intrarea pe
          teritoriul statului a oricărei persoane care nu prezintă suficientă
          încredere chiar dacă aceasta a obţinut acordul pentru obţinerea vizei
          turistice. Agenţia de turism nu poate fi făcută răspunzătoare pentru
          astfel de situaţii ce nu pot fi depistate anterior plecării. Turistul
          îşi asumă riscurile de a-i fi refuzată intrarea şi de aceea este
          obligat să informeze agenţia asupra oricărui incident petrecut
          vreodată în afara graniţelor României, precum şi asupra oricărei
          tangenţe pe care o are cu statele în care are loc desfăşurarea
          pelerinajului.
        </ImportantNote>

        <StyledList>
          <li>
            Minorii trebuie să îndeplinească următoarele condiţii pentru a fi
            lăsaţi să iasă din ţară: să călătorească cu cel puţin un adult
            însoţitor; să aibă asupra lor acordul ambilor părinţi (sau al
            părintelui care nu-i însoţeşte) legalizat la notariat; adultul
            care-i însoţeşte, în cazul în care acesta nu este unul dintre
            părinţi, trebuie să aibă cazier judiciar pe care să-l prezinte la
            frontieră).
          </li>
          <li>
            Programul acţiunii poate fi modificat de către conducătorul de grup,
            ca ordine de desfăşurare sau se pot înlocui unele obiective sau
            hoteluri în funcţie de anumite situaţii speciale.
          </li>
          <li>
            Categoria hotelurilor şi a mijloacelor de transport este cea
            oficială pentru Grecia
          </li>
          <li>
            Distributia camerelor la hotel se face de catre insoțitorul de grup;
            problemele legate de amplasarea sau aspectul camerei se rezolva de
            catre pelerin direct la receptie, asistat de insotitorul de grup.
          </li>
          <li>
            Pentru anumite facilitati din hotel sau din camera, hotelierul poate
            solicita taxe suplimentare (minibar/frigider, seif etc.); in
            momentul sosirii la hotel solicitati receptionerului sa va informeze
            cu exactitate asupra lor.
          </li>
          <li>
            Prezentul document constituie anexa la contractul de prestari
            servicii
          </li>
          <li>
            Agentia nu se obliga sa gaseasca partaj persoanelor care calatoresc
            singure.
          </li>
          <li>
            Biletele de avion sunt nerambursabile si nu putem recupera avansul
            în cazul in care nu doriți să mai finalizați rezervarea.
          </li>
          <li>
            Compania aeriană îşi rezervă dreptul de a modifica orarul de zbor.
          </li>
          <li>
            Compania aeriana nu stabileste locurile in avion, pentru imbarcare
            se aplica metoda „primul venit, primul servit".
          </li>
        </StyledList>
      </ContentSection>

      <ContentSection title="Pregătirea Duhovnicească pentru Pelerinaj">
        <Typography sx={{ ...textStyles.regular, mb: 2 }}>
          Vă sfătuim cu părintească grijă:
        </Typography>
        <StyledList>
          <li>
            Să vă spovediţi şi să luaţi binecuvântarea duhovnicului dvs. pentru
            a face acest Pelerinaj pe urmele Sfinților
          </li>
          <li>Să iertaţi pe cei cu care aţi avut conflicte şi supărări</li>
          <li>Să citiţi în această perioadă din viețile Sfinților, acatiste</li>
          <li>
            Să pregătiţi de acasă pomelnicele cu numele celor apropiaţi –
            pomelnice pe care doriţi să le lăsaţi în bisericile importante din
            Grecia (să scrieţi întotdeauna numele de botez a celui pomenit - cu
            majuscule, fără diminutive)
          </li>
          <li>
            Să pregătiţi batiste noi sau mici obiecte pe care le veţi atinge de
            Icoane făcătoare de minuni și de raclele cu Sfintele Moaște
          </li>
        </StyledList>
      </ContentSection>

      <ContentSection title="Sfaturi Practice pentru Pelerinaj">
        <StyledList>
          <li>
            <strong>PREGĂTIREA GRUPULUI:</strong> În vederea informării
            dumneavoastră, pentru buna organizare şi pentru cunoaşterea
            participanţilor, vă recomandăm călduros să participaţi la
            întâlnirile pregătitoare care se organizează în vederea
            pelerinajului în Grecia.
          </li>
          <li>
            <strong>BANII DE CHELTUIALĂ:</strong> Este bine să aveți de-acasă
            euro, în bancnote mici (1-20 euro). Puteţi foloși şi cardurile și
            cărti internaţionale de credit.
          </li>
          <li>
            <strong>DEPLASĂRILE:</strong> În general, vom foloși autocarul
            pentru a ajunge cât mai aproape de obiectivele propuse dar nu
            întotdeauna vom avea acces cu autocarul în apropierea locurilor şi a
            obiectivelor care ne interesează. De aceea, este bine să aveţi
            încălţăminte comodă.
          </li>
          <li>
            <strong>APARATE FOTO / VIDEO:</strong> Fotografiile păstrează
            amintirea locurilor minunate pe care le-aţi văzut şi a oamenilor pe
            care i-aţi întâlnit. Este bine să aveţi la dumneavoastră un aparat
            foto sau o cameră video. Pelerinajul presupune şi relaţia specială
            care se stabileşte cu oamenii cu care călătoreşti: este bine să
            aveţi cărţi de vizită pe care să le puteţi oferi celor din grup.
          </li>
          <li>
            La programele de pelerinaj recomandăm să se înscrie persoane cu
            stare fizică bună, deoarece vizitele și deplasările pot să dureaze
            7-9 ore / zi (stat în picioare și mers pe jos)
          </li>
          <li>
            <strong>SIGURANŢĂ / SĂNĂTATE:</strong> Asigurarea de călătorie este
            obligatorie, dar este bine să ne informaţi din timp dacă suferiţi de
            afecţiuni deosebite. Să nu uitaţi medicamentele recomandate de
            medicul dvs. Pentru siguranţa dumneavoastră, toate locurile
            importante vizitate de pelerini sunt protejate de poliţia turistică,
            gărzi private şi (sau) subunităţi militare.
          </li>
          <li>
            <strong>CUMPĂRĂTURILE:</strong> Programul nostru de pelerinaj este
            foarte dens, aproape tot timpul este alocat vizitelor şi nu am
            prevăzut mult timp pentru magazine sau pieţe. Acest lucru îl vom
            realiza doar dacă timpul ne va permite (fără să prejudiciem
            programul de vizitare prevăzut). Veţi avea însă, posibilitatea să
            cumpăraţi suvenire care se comercializează în preajma obiectivelor
            vizitate.
          </li>
          <li>
            <strong>ÎMBRĂCĂMINTEA:</strong> Vă recomandăm, pentru propriul dvs.
            confort, să vă gestionaţi corect conţinutul bagajelor, să nu
            transportaţi bagaje grele şi inutile. În pelerinaj nu etalăm
            vestimentaţie pretenţioasă sau bijuterii. Să aveţi în bagajul dvs.
            şi un costum de baie + prosop - nu excludem posibilitatea de a face
            baie în mare. Este bine să vă luaţi îmbrăcămintea obişnuită (casual)
            şi mai ales încălţămintea cea mai comodă. În unele biserici, femeile
            nu au voie să poarte îmbrăcăminte bărbătească (pantaloni). Nu se
            permite intrarea în biserică a femeilor cu umerii neacoperiţi – cu
            tricouri „maieu" (vă recomandăm tricouri și bluze cu mânecuţe). De
            asemenea, este bine ca femeile să intre cu capul acoperit (cu batic)
            în biserici. Recomandăm femeilor să se conformeze acestor cerinţe.
            În zilele în care avem mai multe ore "în mişcare" – este foarte
            practic dacă aveţi un mic rucsac în care să aveţi sticluţa cu apă şi
            alte obiecte esenţiale. În Grecia apa de la robinet este potabilă.
          </li>
        </StyledList>
      </ContentSection>

      <ContentSection title="Nu uitați">
        <Typography sx={textStyles.regular}>
          Nu uitati să puneţi în bagaj:
        </Typography>
        <Typography sx={{ ...textStyles.regular, fontStyle: "italic", mb: 4 }}>
          Credinţa, nădejdea şi dragostea, bucuria, pacea, evlavia, prietenia,
          gânduri, cuvinte frumoase şi dorinţa fierbinte de a ajunge la Locurile
          Sfinte!
        </Typography>

        <Typography sx={{ ...textStyles.bold, mb: 2, textAlign: "center" }}>
          Ne bucurăm că aţi decis să faceţi acest pelerinaj împreună cu noi!
        </Typography>
      </ContentSection>
    </StyledContainer>
  );
};

export default PelerinajGrecia;
