import React from "react";
import {
  Container,
  Typography,
  Box,
  Grid,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";
import QuoteSection from "./QuoteSection";

const contentData = [
  {
    id: 1,
    title: "Despre Agenție",
    content: `Agentia Pelerinul Crestin a Episcopiei Ortodoxe Române a Maramuresului si Sătmarului cu ajutorul lui Dumnezeu, cu binecuvântarea și purtarea de grijă a Preasfintitului Părinte IUSTIN, este licentiată ca agentie de turism/pelerinaje organizatoare, putând organiza și derula pelerinaje, pachete și programe turistice religioase, culturale etc. proprii precum și ale altor agentii partenere, conform legii.

 Este singura agenție de pelerinaje licențiată din Maramureș și Satu Mare, specializată în turism religios și de pelerinaj cu experiență de peste 7 ani.

Sfânta Impărăteasă Elena Ocrotitoarea Pelerinilor si turistilor care călătoresc cu Agenția Pelerinul Creștin a Episcopiei Ortodoxe Române a Maramureșului și Sătmarului.

Tuturor celor care au fost pelerini alături de noi le mulțumim, iar cei care doresc să călătorească în pelerinaje duhovnicești alături de noi îi așteptăm cu drag!
`,
    image: "/images/despre_agentie.png",
    imagePosition: "left",
    borderLine: "false",
  },
  {
    id: 2,
    title: "Cuvântul Preasfințitului Părinte IUSTIN",
    content: `,, Iubiți fii duhovnicești,
Viața creștinului de la venirea pe lumea aceasta văzută și primirea Tainei Sfântului Botez, prin care devenim fii ai lui Dumnezeu după har și până la plecarea din ea și întoarcerea sufletului la Dumnezeu în lumina și împărăția Preasfintei Treimi, este o călătorie care urmează același traseu pe care l-a urmat și viața Mântuitorului, "Câți în Hristos v-ați botezat, în Hristos v-ați și îmbrăcat" (Taina Botezului).

Suntem, deci după chipul lui Hristos, prin nașterea din apă și din duh, creștem cu harul și cu înțelepciunea înaintea lui Dumnezeu și a oamenilor, viața o trăim cu bucurii, întristări și necazuri și purtăm crucea suferințelor și umilințelor, ca parte a ființei noastre, care aparține pământului și cerului deodată și în final bem paharul morții pe care l-a băut Hristos, și care ne duce către Învierea în care credem cu toată puterea noastră și pe care nădăjduim să o dobândim.

Să prețuim cum se cuvine jertfa strămoșilor, să ne cinstim sfinții și oamenii mari ai neamului, să învățăm și să memorăm istoria adevărată pentru a nu repeta greșelile trecutului și să păzim cu sfințenie moștenirea primită de la înaintași: O țară românească, o Biserică ortodoxă strămoșească, o credință dreaptă, o limbă a părinților noștri, în dulcele grai românesc și tot tezaurul sacru pe care l-au creat strămoșii și ni l-au lăsat în dar pentru a ne asigura dăinuirea și mântuirea în Hristos Domnul cel înviat din morți pentru a ne dărui Învierea și viața veșnică alături de toate neamurile pământului.

Al vostru, al tuturor, de tot binele voitor și împreună cu voi rugător pentru darul vieții veșnice.

† IUSTIN
Episcop al Maramureșului și Sătmarului`,
    image: "/images/cuvant-episcop.jpg",

    variant: "quote",
    borderLine: "false",
  },
  {
    id: 3,
    title: "Ce este pelerinajul?",
    content: `În cadrul pelerinajului, componenta duhovnicească este cea mai importantă, prezența divinului, comunicarea cu divinitatea, predominând rugăciunea în diversele ei forme. Pelerinajul poate fi îmbinat armonios și cu alte tipuri de turism, care vin în completare, cum ar fi turismul religios, cultural, recreațional, curativ și experiențial.

Un pelerinaj are la bază activitățile mântuitoare și sfințitoare și este un subiect de studiu interdisciplinar care niciodată nu va fi epuizat. Experiența pelerinajelor mă îndreptățește să afirm că de fiecare dată un loc sacru poate să fie abordat din alt unghi, iar trăirile, emoțiile și sentimentele să fie diferite și ascendente.

Turismul religios este foarte apropiat de pelerinaj, acestea își au originea în cele mai vechi timpuri precreștine, dar au înflorit odată cu venirea Mântuitorului – Pelerinul cel Veșnic care, imediat după înviere, a călătorit într-un pelerinaj cu Luca și Cleopa spre Emaus.

Locurile de pelerinaj din întreaga lume nu au fost sacre de la început, a existat un proces de sacralizare, prin rugăciune, prin săvârșirea anumitor minuni, prin prezența sfinților sau tocmai prin prezența lui Dumnezeu cel întreit în persoane.`,
    image: "/images/ce-este-pelerinajul.png",
    imagePosition: "left",
    borderLine: "true",
  },
  {
    id: 4,
    title: "Etapele Pelerinajului",
    content: `Etapele sunt foarte importante și trebuie parcurse cu atenție și la timpul potrivit; să-i acordăm timpului timp. Ceea ce nu se întâmplă la timp sau în timpul potrivit nu este trainic.

Pelerinajul are următoarele etape:
• Pregătirea (înainte de derularea concretă)
• Drumul spre locul sacru
• Derularea concretă – punctul culminant – sărbătoarea
• Drumul de întoarcere
• Pelerinajul de după pelerinaj, metanoia continuă

Pregătirea trupească și sufletească pentru derularea unui pelerinaj trebuie să fie în împreună lucrare, colaborare și comuniune. Fiecare participant încearcă să aibe toate cele necesare, atât din punct de vedere material cât și duhovnicesc.`,
    image: "/images/IMG_5704.JPG",
    imagePosition: "right",
    borderLine: "true",
  },
];

const AboutUs = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const Section = ({
    title,
    content,
    image,
    borderLine,
    imagePosition,
    variant = "default",
  }) => {
    const ContentWrapper = ({ children }) =>
      variant === "quote" ? (
        <Box sx={{ width: "100%", bgcolor: "#FDFBD6", py: 8 }}>
          <Container maxWidth="lg">{children}</Container>
        </Box>
      ) : (
        <Container maxWidth="lg">{children}</Container>
      );

    return (
      <ContentWrapper>
        <Box
          sx={{
            display: "flex",
            flexDirection: { xs: "column", md: "row" },
            gap: 5,
            my: variant === "quote" ? 0 : { xs: 12, md: 15 },
            alignItems: { md: "center" },
          }}
        >
          {!isMobile && imagePosition === "left" && (
            <Box
              sx={{
                flex: 1,
                maxHeight: 500,
                "& img": {
                  borderRadius: 0,
                  objectFit: "cover",
                  width: "100%",
                  height: "100%",
                },
              }}
            >
              <img src={image} alt={title} />
            </Box>
          )}

          <Box
            sx={{
              flex: 1,
              display: "flex",
              flexDirection: "column",
              background: "white",
            }}
          >
            <Box sx={{ mb: 3, pb: 1 }}>
              <Typography
                variant="h4"
                component="h2"
                sx={{
                  fontWeight: 700,
                  textAlign: variant === "quote" ? "center" : "left",
                  fontFamily: "Libre Baskerville",
                  color: variant === "quote" ? "#BE9257" : "black",
                }}
              >
                {title}
              </Typography>
            </Box>
            <Typography
              variant="body1"
              sx={{
                whiteSpace: "pre-line",
                textAlign: variant === "quote" ? "justify" : "left",
                lineHeight: 1.7,
                fontFamily: "Inter",
                color: variant === "quote" ? "#800020" : "black",
                fontSize: "14px",
                fontWeight: 500,
              }}
            >
              {content}
            </Typography>
          </Box>

          {!isMobile && imagePosition === "right" && (
            <Box
              sx={{
                flex: 1,
                height: 500,
                "& img": {
                  borderRadius: 0,
                  objectFit: "cover",
                  width: "100%",
                  height: "100%",
                },
              }}
            >
              <img src={image} alt={title} />
            </Box>
          )}

          {isMobile && (
            <Box
              sx={{
                width: "100%",
                height: 300,
                "& img": {
                  borderRadius: 0,
                  objectFit: "cover",
                  width: "100%",
                  height: "100%",
                },
              }}
            >
              <img src={image} alt={title} />
            </Box>
          )}
        </Box>
        {borderLine === "true" && (
          <Box
            sx={{
              width: "100%",
              height: "1px",
              background:
                "linear-gradient(90deg, transparent, #BE9257 50%, transparent)",
              my: 4,
            }}
          />
        )}
      </ContentWrapper>
    );
  };

  const images = [
    "IMG_5647.JPG",
    "IMG_5648.JPG",
    "IMG_5649.JPG",
    "IMG_5650.JPG",
    "IMG_5651.JPG",
    "IMG_5653.JPG",
    "IMG_5654.JPG",

    "IMG_5655.JPG",
    "IMG_5699.JPG",
    "IMG_5700.JPG",
    "IMG_5701.JPG",
    "IMG_5702.JPG",
    "IMG_5703.JPG",
    "IMG_5704.JPG",
    "IMG_5705.JPG",
    "IMG_5706.JPG",
    "IMG_5707.JPG",
    "IMG_5708.JPG",
    "IMG_5709.JPG",
    "IMG_5710.JPG",
    "IMG_5711.JPG",
    "IMG_5712.JPG",
    "IMG_5713.JPG",
    "IMG_5714.JPG",
    "IMG_5715.JPG",
    "IMG_5716.JPG",
    "IMG_5718.JPG",
    "IMG_5719.JPG",
    "IMG_5720.JPG",
    "IMG_5721.JPG",
    "IMG_5722.JPG",
    "IMG_5723.JPG",
    "IMG_5724.JPG",
    "IMG_5725.JPG",
    "IMG_5726.JPG",
    "IMG_5727.JPG",
    "IMG_5728.JPG",
    "IMG_5729.JPG",
    "IMG_5772.JPG",
    "IMG_5773.JPG",
  ];

  return (
    <Box>
      {/* Header Section */}

      {/* Content Sections */}
      {contentData.map((section) =>
        section.variant === "quote" ? (
          <QuoteSection key={section.id} />
        ) : (
          <Section
            key={section.id}
            title={section.title}
            content={section.content}
            image={section.image}
            imagePosition={section.imagePosition}
            variant={section.variant}
            borderLine={section.borderLine}
          />
        )
      )}

      <Container maxWidth="lg" sx={{ py: 4, mb: 2 }}>
        <Typography
          variant="h4"
          component="h2"
          gutterBottom
          sx={{
            fontWeight: 700,
            textAlign: "center",
            fontFamily: "Libre Baskerville",
            color: "black",
          }}
        >
          Mai multe poze din pelerinaje
        </Typography>

        <Typography
          variant="body2"
          sx={{
            maxWidth: { xs: "90%", sm: "100%" },
            mx: "auto",
            fontFamily: "Inter",
            textAlign: "center",
            mb: 8,
            px: { xs: 2, sm: 0 },
            fontSize: { xs: "0.875rem", sm: "1rem" },
          }}
        >
          Glisați stânga-dreapta pentru a vedea mai multe poze.
        </Typography>
        <Box sx={{ position: "relative" }}>
          <Swiper
            modules={[Navigation]}
            navigation={{
              nextEl: ".custom-next",
              prevEl: ".custom-prev",
            }}
            spaceBetween={10}
            slidesPerView={1}
            breakpoints={{
              640: {
                slidesPerView: 2,
                spaceBetween: 10,
              },
              768: {
                slidesPerView: 3,
                spaceBetween: 10,
              },
            }}
            loop={true}
            autoplay={{ delay: 1000 }}
            className="max-w-6xl mx-auto"
          >
            {images.map((image, index) => (
              <SwiperSlide key={index}>
                <div className="max-w-full max-h-96 overflow-hidden">
                  <img
                    src={`/images/${image}`}
                    alt={`Travel ${index + 1}`}
                    className="w-full h-full object-cover"
                    style={{
                      height: "384px",
                      borderRadius: "0px",
                      width: "380px",
                      objectFit: "cover",
                    }}
                  />
                </div>
              </SwiperSlide>
            ))}

            {/* Custom Navigation Arrows */}
          </Swiper>
        </Box>
      </Container>
    </Box>
  );
};

export default AboutUs;
