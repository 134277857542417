import React from "react";
import { useNavigate } from "react-router-dom";
import {
  Card,
  CardContent,
  CardActions,
  Button,
  Typography,
  Grid,
  Container,
  CardMedia,
  Box,
} from "@mui/material";
import { CustomContainedButton } from "../../components/OutlinedButton";

const CardPelerinaj = ({ titlu, data, pret, transport, path, image }) => {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate(path);
  };

  return (
    <Card
      sx={{
        height: "100%",
        display: "flex",
        flexDirection: "column",
        margin: "0 8px",
        borderRadius: "8px",
        boxShadow: "none",
        border: "1px solid lightgrey",
      }}
    >
      <CardMedia
        component="img"
        height="200"
        image={`${image}`}
        alt={titlu}
        sx={{ objectFit: "cover" }}
      />
      <CardContent
        sx={{
          p: 2,
          height: "100%",
          textAlign: "left",
          justifyContent: "space-between",
          justifyItems: "flex-start",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Typography
          variant="h6"
          gutterBottom
          component="h3"
          sx={{ fontSize: "1.1rem", fontWeight: "600", fontFamily: "Inter" }}
        >
          {titlu}
        </Typography>
        <Box className="space-y-2">
          <Typography
            variant="body2"
            gutterBottom
            color="text.secondary"
            sx={{ fontFamily: "Inter", fontWeight: 500 }}
          >
            <span className="font-semibold">Data:</span> {data}
          </Typography>
          <Typography
            gutterBottom
            variant="body2"
            color="text.secondary"
            sx={{ fontFamily: "Inter", fontWeight: 500 }}
          >
            <span className="font-bold">Preț:</span> {pret}
          </Typography>
          <Typography
            variant="body2"
            color="text.secondary"
            sx={{ fontFamily: "Inter", fontWeight: 500 }}
          >
            <span className="font-semibold">Transport:</span> {transport}
          </Typography>
        </Box>
      </CardContent>
      <CardActions
        sx={{
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyItems: "center",
          justifyContent: "center",
          mb: 2,
        }}
      >
        <CustomContainedButton text="Vezi Detalii" onClick={handleClick} />
      </CardActions>
    </Card>
  );
};

export const ListaPelerinaje = () => {
  const pelerinajeExterne = [
    {
      titlu: "Pelerinaj în Cappadocia și Asia Mică",
      data: "20 ianuarie 2025",
      pret: "1100 EURO",
      transport: "Avion + Autocar",
      path: "/pelerinaje/cappadocia",
      image: "/images/IMG_5655.JPG",
    },
    {
      titlu: "Pelerinaj în Grecia",
      data: "21 - 28 Mai 2025",
      pret: "1100 Euro",
      transport: "Avion + Autocar",
      path: "/pelerinaje/grecia",
      image: "/images/IMG_5651.JPG",
    },
    {
      titlu: "Pelerinaj în 𝗜𝗦𝗥𝗔𝗘𝗟",
      data: "𝟵 - 𝟭𝟯 𝗺𝗮𝗶 𝟮𝟬𝟮𝟱",
      pret: "850 Euro",
      transport: "Avion + Autocar",
      path: "/pelerinaje/israel",
      image: "/images/israel.jpeg",
    },
    {
      titlu: "Pelerinaj în Sfântul Munte Athos",
      data: "25 - 30 Martie 2025",
      pret: "600 Euro",
      transport: "Autocar + Feribot",
      path: "/pelerinaje/athos",
      image: "/images/IMG_5707.JPG",
    },
  ];

  const pelerinajeInterne = [
    {
      titlu: "Pelerinaj în Moldova și Bucovina",
      data: "14-16 martie 2025",
      pret: "1000 LEI",
      transport: "Autocar",
      path: "/pelerinaje/moldova",
      image: "/images/IMG_5648.JPG",
    },
    {
      titlu: "Pelerinaj Pe urmele Sfinților Brancoveni",
      data: "În curând",
      pret: "1000 lei",
      transport: "Autocar",
      path: "/pelerinaje/romania-grecia",
      image: "/images/IMG_5653.JPG",
    },
    {
      titlu: "Pelerinaj în Banat și Cazanele Dunării",
      data: "𝟮𝟱-𝟮𝟳 𝗮𝗽𝗿𝗶𝗹𝗶𝗲 𝟮𝟬𝟮𝟱.",
      pret: "1000 lei",
      transport: "Autocar",
      path: "/pelerinaje/banat-cazane",
      image: "/images/IMG_5649.JPG",
    },
    {
      titlu: "Pelerinaj în București și împrejurimi",
      data: "În curând",
      pret: "În curs de actualizare",
      transport: "Autocar",
      path: "/pelerinaje/bucuresti",
      image: "/images/IMG_5728.JPG",
    },
    {
      titlu: "Pelerinaj în Transilvania",
      data: "În curând",
      pret: "În curs de actualizare",
      transport: "Autocar",
      path: "/pelerinaje/transilvania",
      image: "/images/IMG_5772.JPG",
    },
  ];

  return (
    <Container className="py-8" sx={{ height: "100%", mt: 12, mb: 12 }}>
      <Typography
        variant="h4"
        component="h2"
        sx={{
          fontWeight: 700,
          textAlign: "left",
          fontFamily: "Libre Baskerville",
          color: "black",
          mb: 5,
          borderBottom: `2px solid #800020`,
          paddingBottom: "10px",
        }}
      >
        Pelerinaje Externe
      </Typography>
      <Grid container spacing={3}>
        {pelerinajeExterne.map((pelerinaj, index) => (
          <Grid item xs={12} md={4} key={index}>
            <CardPelerinaj {...pelerinaj} />
          </Grid>
        ))}
      </Grid>
      <Typography
        variant="h4"
        component="h2"
        sx={{
          fontWeight: 700,
          textAlign: "left",
          fontFamily: "Libre Baskerville",
          color: "black",
          mb: 5,
          mt: 12,
          borderBottom: `2px solid #800020`,
          paddingBottom: "10px",
        }}
      >
        Pelerinaje Interne
      </Typography>
      <Grid container spacing={3}>
        {pelerinajeInterne.map((pelerinaj, index) => (
          <Grid item xs={12} md={4} key={index}>
            <CardPelerinaj {...pelerinaj} />
          </Grid>
        ))}
      </Grid>
    </Container>
  );
};

export default ListaPelerinaje;
